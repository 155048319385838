

<div class="row " #myDiv>
    <!-- Datatables -->
    <div class="col-lg-12">
        <div class="card mb-4 border-0" style="border-radius: 13px;">

            <div  class="table-responsive p-3">
                <table class="table align-items-center table-flush" id="dataTable1">
                    <thead class="head-table">
                        <tr>
                            
                            <th >{{'languages.listing.actions' | translate}}
                            </th>


                            <th>{{'languages.SigafConfig.RagistrNum' | translate}}</th>
                            <th>{{'languages.listing.Type' | translate}}</th>
                            <th>{{'languages.SigafConfig.Denomination' | translate}}</th>
                            <th>{{'languages.affaire.directeur' | translate}}</th>
                            
                            <th>{{'languages.user.goverment' | translate}}</th>
                            
                            <th>{{'languages.SigafConfig.delegation' | translate}}</th>
                            <th>{{'languages.SigafConfig.matriculeFiscale' | translate}}</th>
                            <th>{{'languages.prospect.phone' | translate}}</th>
                            <th>{{'languages.opportunite.natureFormation' | translate}}</th>
                            <th>{{'languages.affaire.dateDeffet' | translate}}</th>
                            <th  >
                                {{'languages.opportunite.statutInterne' | translate}}
                           
                            </th>


                            <th  >
                                {{'languages.opportunite.label_status' | translate}}
                                <div class="d-inline-block" >
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th>
                            <th>{{'languages.affaire.Motifs' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody class="body-table">
                        <tr *ngFor="let affaires of favorites; let i = index">
                          
                            <td>
                                <div class="d-flex justify-content-center align-items-center">
                                    <!-- <div class="pt-1">
                                        <button type="button" class="btn-dt-list" data-toggle="modal" data-target="#ActionsPhone" (click)="getPhoneList(affaires.id_prospect)">
                                                <img src="/assets/icons/phone-icon.svg">
                                            </button>
                                    </div> -->
                                 

                                    <!-- In your template -->
                                <div class="mr-1">
                                    <a type="button" class="btn-dt-list" [routerLink]="['/affaires/details/', affaires.affaire_id]" [title]="'languages.navbar.consult' | translate">
                                        <i class="fa-solid eyebtnListing fa-eye fa fa-lg" ></i>
                                    
                                    </a>
                                </div>
                                
                                <div>
                                    <input type='checkbox' id="permanent_{{i}}" title="Add Favoris" class="star"
                                        [checked]="true" />
                                    <label for="permanent_{{i}}"
                                        (click)="add_suivi_affaire(affaires.affaire_id, affaires.id_contrat)"></label>
                                </div>



                                </div>
                            </td>
                            <td>
                                {{
                                    affaires?.registration_number 
                                        ? affaires.registration_number 
                                        : '__'
                                }}
                            </td>
                            <td>
                                {{
                                    affaires?.ligne_produit 
                                        ? affaires.ligne_produit 
                                        : '__'
                                }}
                            </td>
                            
                            <td>
                              <ng-container *ngIf="affaires?.establishment_data_commercial_name || affaires?.establishment_data_official_name  || affaires?.legal_entity_official_name">
                                {{affaires.establishment_data_commercial_name || affaires.establishment_data_official_name ||affaires.legal_entity_official_name }}</ng-container>
                             </td>
                           <td  > 
                              <ng-container *ngIf="affaires?.establishment_director_data_last_name  || affaires?.establishment_director_data_first_name">
                                {{ affaires.establishment_director_data_last_name  + " " + affaires.establishment_director_data_first_name }}
                              </ng-container>
                            </td>
                            <td  *ngIf="affaires?.establishment_data_governorate">
                                {{affaires.establishment_data_governorate}}
                            </td>
                            
                            <td>
                              <ng-container *ngIf="affaires?.establishment_data_delegation">
                                {{affaires.establishment_data_delegation}}
                              </ng-container>
                             
                            </td>

                            

                              <td>
                                <ng-container *ngIf="affaires?.establishment_data_tax_number || affaires?.legal_entity_tax_number || affaires?.numero_matricule_fiscal_numero_IURNE ">
                                  {{affaires?.establishment_data_tax_number || affaires?.legal_entity_tax_number || affaires?.numero_matricule_fiscal_numero_IURNE}}
                                </ng-container>
                               
                              </td>

                              <td>
                                <ng-container *ngIf="affaires?.investor_phone_number ">
                                  {{affaires.investor_phone_number }}
                                </ng-container>
                               
                              </td>
                              
                              <td>
                                <ng-container *ngIf="affaires?.establishment_data_training_type ">
                                  {{affaires.establishment_data_training_type }}
                                </ng-container>
                               
                              </td>

                           
                         
                            
                            
                        <td>{{affaires.creation | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                            <td>
                                
                                {{affaires.name_service_affaire}}
                        </td>
                            <td>
                                <div class="btn-status d-flex justify-content-center"
                                    [style.background]="affaires.etat_background" [style.color]="affaires.etat_color">
                                    {{affaires.etat}}</div>
                            </td>
                            
                            
                            <td *ngIf="affaires.libele_motif == null">-</td>
                            <td *ngIf="affaires.libele_motif !==null">
                                <div class="pb-1" id="tagsList">
                                    <mat-chip-set class="example-chip ">
                                        <mat-chip class="example-box  mt-2"
                                            [ngStyle]="{'background-color':affaires.background_motif, 'color':affaires.color_motif}">
                                            {{affaires.libele_motif}}
                                        </mat-chip>
                                    </mat-chip-set>
                                </div>


                            </td>
                        </tr>

                    </tbody>

                </table>
                <div class="row d-flex justify-center align-items-center text-center ">
                    <p *ngIf="favorites?.length===0"  class="col-12 noAffaire"> {{'languages.affaire.pas_daffaire' | translate}}  </p>
                   </div>

            </div>

       

            <!-- <div class="table-responsive p-3">
                <table class="table align-items-center table-flush" id="dataTable1">
                    <thead class="head-table">
                        <tr>
                            <th>

                            </th>
                            <th >{{'languages.listing.actions' | translate}}
                            </th>
                         <th>{{'languages.opportunite.label_creation' | translate}}
                                <div class="d-inline-block"  >
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th>
                            <th  >{{'languages.affaire.label_client' | translate}}
                                <div class="d-inline-block"  >
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th>

                            <th  >{{'languages.opportunite.label_product' | translate}}
                              <div class="d-inline-block"  >
                                  <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                              </div>
                          </th>

                         <th   > Affectée à
                                <div class="d-inline-block"  >
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th>

                          <th   >
                                {{'languages.opportunite.label_status' | translate}}
                                <div class="d-inline-block"  >
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th>
                        
                        </tr>
                    </thead>
                    <tbody class="body-table">
                        <tr *ngFor="let affaires of favorites; let i = index">
                            <td>
                              <div class="mt-2">
                            
                              </div>
                            </td>
                            <td>
                              <div class="d-flex justify-content-center align-items-center">
                               
                                <div>
                                  <a *ngIf="affaires.affaire_id" type="button" class="btn-dt-list"
                                     [routerLink]="['/affaires/details/', affaires.affaire_id]">
                                    <img src="/assets/icons/edit-icon.svg">
                                  </a>
                                </div>
                                
                                <div>
                                  <input type='checkbox' id="permanent_{{i}}" title="Add Favoris" class="star"
                                      [checked]="true" />
                                  <label for="permanent_{{i}}"
                                      (click)="add_suivi_affaire(affaires.affaire_id, affaires.id_contrat)"></label>
                                </div>
                              </div>
                            </td>
                            <td>{{affaires.creation | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                            <td>{{affaires.creator_name}}</td>
                            <td>{{affaires.ligne_produit}}</td>
                            <td>
                           
                              <ng-container *ngIf="affaires.nom_affected || affaires.prenom_affected; else notYetAffected">
                                {{affaires.nom_affected + " " + affaires.prenom_affected }}
                              </ng-container>
                              <ng-template #notYetAffected>
                                <small class="text-secondary">Pas encore affectée</small>
                              </ng-template>
                            </td>
                            <td>
                              <div class="btn-status d-flex justify-content-center" [style.background]="affaires.etat_background" [style.color]="affaires.etat_color">
                                {{affaires.statu}}
                              </div>
                            </td>
                           
                          </tr>


                    </tbody>

                </table>
                 <div class="row d-flex justify-center align-items-center text-center ">
                    <p *ngIf="favorites?.length===0"  class="col-12 noAffaire"> {{'languages.affaire.pas_daffaire' | translate}}  </p>
                   </div>

            </div> -->
        </div>
       <!--  <div class="navbar-center row mb-4">
            <div class="col pt-1">
                <div class="row w-50 flex-nowrap">
                    <div class="col mt-1">
                        <p>{{'languages.miniListing.Afficher' | translate}}</p>
                    </div>
                    <div class="col mx-1">
                        <select [(ngModel)]="limit" (change)="updateListAffaires()"
                            class="bg-white border pt-1 rounded font-weight-bold">
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div class="col mt-1">
                        <p class="text-nowrap"> {{'languages.miniListing.pagesPerPage' | translate}} {{totalLength}}  {{'languages.miniListing.element' | translate}} </p>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <nav aria-label="Page navigation example" *ngIf="totalPages>0">
                    <ul class="pagination pg-blue">
                        <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Previous">
                                <i class="fa fa-chevron-left "></i>
                            </a>
                        </li>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+page-3>0" class="page-item">
                                <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page -
                                    3}}</a>
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+page<totalPages" class="page-item">
                                <a (click)="changepage(i+page)" class="page-link mt-2 border rounded"
                                    [ngClass]="{'active-page':page==(i+page)}">
                                    {{i + page}}
                                </a>
                            </li>
                        </ng-container>
                        <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
                        <li class="page-item">
                            <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                                [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
                        </li>
                        <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Next">
                                <i class="fa fa-chevron-right"></i>
                            </a>
                        </li>
                        <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col">
                <div class="row position-absolute flex-nowrap" style="right: 0px">
                    <div class="col text-nowrap text-right mt-2">
                        <p>{{'languages.listing.label_goto' | translate}}</p>
                    </div>
                    <div class="col mt-1 ml-2">
                        <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48"
                            (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number"
                            style="margin-left: 4px;" size="1"
                            class="page_input bg-white border rounded font-weight-bold" [value]="page"
                            max="{{totalPages}}">
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>

