import { isEqual } from 'lodash';
import { ApiAdminBpmService } from './../../../../services/ApiAdminBpm/api-admin-bpm.service';
/* eslint-disable dot-notation */
import { InformationsComplmentaire } from './../../../../entity/Opportunity/InformationsComplmentaire';
import { InformationsSpecifique } from './../../../../entity/Opportunity/InformationsSpecifique';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AffaireDetails } from 'src/app/entity/Affaires/AffaireDetails';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiOpportunitService } from './../../../../services/ApiOpportunite/api-opportunite.service';
import { Component, Input, OnInit, Output, EventEmitter, HostListener, OnDestroy, SimpleChanges } from '@angular/core';
import swal from 'sweetalert2';

import { forkJoin, of, Subscription } from 'rxjs';
import { SurveyModel, StylesManager, QuestionPanelDynamicModel } from 'survey-core';
import * as moment from 'moment';
import mime from 'mime';
import { environment } from 'src/environments/environment';
import { V } from '@angular/cdk/keycodes';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-information-avancees',
  templateUrl: './information-avancees.component.html',
  styleUrls: ['./information-avancees.component.css'],
})
export class InformationAvanceesComponent implements OnInit, OnDestroy {
  form = new FormGroup({});
  surveyModel: SurveyModel;

  modelspecif: any = {};
  modelcomp: any = {};
  ListeofUnsbscribeservice: Subscription[] = [];
  options: FormlyFormOptions = {};
  fieldscomp = [];
  fieldsspecif = [];
  formdatacomp = new FormGroup({});
  formdataspecif = new FormGroup({});

  formComplementaire = new FormGroup({});
  formSpecifique = new FormGroup({});
  loading: boolean = false;
  @Output() formLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() demandeType:string
  @Input() informationsContrat: AffaireDetails;
  @Input() affaire_id: string = '';
  @Input() id_opportunite: string = '';
  infoComplementaire: InformationsComplmentaire;
  infoSpecifique: InformationsSpecifique;
  @Output() infoSpecifiqueexport: EventEmitter<any> = new EventEmitter();
  @Output() infoComplementaireexport: EventEmitter<any> = new EventEmitter();
  @Input() allowed_to_update_affaire: boolean;
  @Output() dataForm: EventEmitter<any> = new EventEmitter();
  @Output() allowUpdateStatus: EventEmitter<any> = new EventEmitter();
  suveyInfo: any;
  specifique = false;
  complementaire = false;
  schemaFormUpdated: boolean = false;
  nationalities: any[] = [];
  goverments: any[] = [];
  delegation: any[] = [];
  formes: any[] = [];

  secteurs: any[] = [];
  Soussecteurs: any[] = [];
  limit = 15;
  page = 1;
  search: string = '';
  totalLength: number = 0;
  diplome_specialite: any[] = [];
  totalPages = 0;
  userInfo: any;
  subscriptions: Subscription[] = [];
  nationaliteFormControl = new FormControl();
  alerts:any
  themeJson: any = {
    themeName: 'default',
    colorPalette: 'light',
    isPanelless: false,
    backgroundImage: '',
    backgroundOpacity: 1,
    backgroundImageAttachment: 'scroll',
    backgroundImageFit: 'cover',
    cssVariables: {
      '--sjs-corner-radius': '4px',
      '--sjs-base-unit': '8px',
      '--sjs-shadow-small': '0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
      '--sjs-font-questiontitle-weight': '400',
      '--sjs-font-questiontitle-size': '15px',
      '--sjs-font-questiondescription-size': '18px',
      '--sjs-shadow-inner': 'inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
      '--sjs-border-default': 'rgba(0, 0, 0, 0.16)',
      '--sjs-border-light': 'rgba(0, 0, 0, 0.09)',
      '--sjs-general-backcolor': 'rgba(255, 255, 255, 1)',
      '--sjs-general-backcolor-dark': 'rgba(248, 248, 248, 1)',
      '--sjs-general-backcolor-dim-light': 'rgba(249, 249, 249, 1)',
      '--sjs-general-backcolor-dim-dark': 'rgba(243, 243, 243, 1)',
      '--sjs-general-forecolor': 'rgba(0, 0, 0, 0.91)',
      '--sjs-general-forecolor-light': 'rgba(0, 0, 0, 0.45)',
      '--sjs-general-dim-forecolor': 'rgba(0, 0, 0, 0.91)',
      '--sjs-general-dim-forecolor-light': 'rgba(0, 0, 0, 0.45)',
      '--sjs-secondary-backcolor': 'rgba(255, 152, 20, 1)',
      '--sjs-secondary-backcolor-light': 'rgba(255, 152, 20, 0.1)',
      '--sjs-secondary-backcolor-semi-light': 'rgba(255, 152, 20, 0.25)',
      '--sjs-secondary-forecolor': 'rgba(255, 255, 255, 1)',
      '--sjs-secondary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
      '--sjs-shadow-small-reset': '0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
      '--sjs-shadow-medium': '0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
      '--sjs-shadow-large': '0px 8px 16px 0px rgba(0, 0, 0, 0.1)',
      '--sjs-shadow-inner-reset': 'inset 0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
      '--sjs-border-inside': 'rgba(0, 0, 0, 0.16)',
      '--sjs-special-red-forecolor': 'rgba(255, 255, 255, 1)',
      '--sjs-special-green': 'rgba(25, 179, 148, 1)',
      '--sjs-special-green-light': 'rgba(25, 179, 148, 0.1)',
      '--sjs-special-green-forecolor': 'rgba(255, 255, 255, 1)',
      '--sjs-special-blue': 'rgba(67, 127, 217, 1)',
      '--sjs-special-blue-light': 'rgba(67, 127, 217, 0.1)',
      '--sjs-special-blue-forecolor': 'rgba(255, 255, 255, 1)',
      '--sjs-special-yellow': 'rgba(255, 152, 20, 1)',
      '--sjs-special-yellow-light': 'rgba(255, 152, 20, 0.1)',
      '--sjs-special-yellow-forecolor': 'rgba(255, 255, 255, 1)',
      '--sjs-article-font-xx-large-textDecoration': 'none',
      '--sjs-article-font-xx-large-fontWeight': '700',
      '--sjs-article-font-xx-large-fontStyle': 'normal',
      '--sjs-article-font-xx-large-fontStretch': 'normal',
      '--sjs-article-font-xx-large-letterSpacing': '0',
      '--sjs-article-font-xx-large-lineHeight': '64px',
      '--sjs-article-font-xx-large-paragraphIndent': '0px',
      '--sjs-article-font-xx-large-textCase': 'none',
      '--sjs-article-font-x-large-textDecoration': 'none',
      '--sjs-article-font-x-large-fontWeight': '700',
      '--sjs-article-font-x-large-fontStyle': 'normal',
      '--sjs-article-font-x-large-fontStretch': 'normal',
      '--sjs-article-font-x-large-letterSpacing': '0',
      '--sjs-article-font-x-large-lineHeight': '56px',
      '--sjs-article-font-x-large-paragraphIndent': '0px',
      '--sjs-article-font-x-large-textCase': 'none',
      '--sjs-article-font-large-textDecoration': 'none',
      '--sjs-article-font-large-fontWeight': '700',
      '--sjs-article-font-large-fontStyle': 'normal',
      '--sjs-article-font-large-fontStretch': 'normal',
      '--sjs-article-font-large-letterSpacing': '0',
      '--sjs-article-font-large-lineHeight': '40px',
      '--sjs-article-font-large-paragraphIndent': '0px',
      '--sjs-article-font-large-textCase': 'none',
      '--sjs-article-font-medium-textDecoration': 'none',
      '--sjs-article-font-medium-fontWeight': '700',
      '--sjs-article-font-medium-fontStyle': 'normal',
      '--sjs-article-font-medium-fontStretch': 'normal',
      '--sjs-article-font-medium-letterSpacing': '0',
      '--sjs-article-font-medium-lineHeight': '32px',
      '--sjs-article-font-medium-paragraphIndent': '0px',
      '--sjs-article-font-medium-textCase': 'none',
      '--sjs-article-font-default-textDecoration': 'none',
      '--sjs-article-font-default-fontWeight': '400',
      '--sjs-article-font-default-fontStyle': 'normal',
      '--sjs-article-font-default-fontStretch': 'normal',
      '--sjs-article-font-default-letterSpacing': '0',
      '--sjs-article-font-default-lineHeight': '28px',
      '--sjs-article-font-default-paragraphIndent': '0px',
      '--sjs-article-font-default-textCase': 'none',
      '--sjs-general-backcolor-dim': 'rgba(0, 0, 255, 0)',
      '--sjs-primary-backcolor': '#21A8A8',
      '--sjs-primary-backcolor-dark': 'rgba(30, 153, 153, 1)',
      '--sjs-primary-backcolor-light': 'rgba(33, 168, 168, 0.1)',
      '--sjs-primary-forecolor': 'rgba(255, 255, 255, 1)',
      '--sjs-primary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
      '--sjs-special-red': 'rgba(229, 10, 62, 1)',
      '--sjs-special-red-light': 'rgba(229, 10, 62, 0.1)',
    },
    headerView: 'basic',
  };

  @Output() onUpdateinformationsSpecifique: EventEmitter<InformationsSpecifique> = new EventEmitter();
  @Output() emitForminformationsSpecifique: EventEmitter<any> = new EventEmitter();
  addedQuestions: any;
  codePostal: any;
  isEpfExistant: any =false;

  constructor(
    private apiOpportunitService: ApiOpportunitService,
    private apiAffairesService: ApiAffairesService,
    private formlyJsonschema: FormlyJsonschema,
    private ApiAdminbpmService: ApiAdminBpmService,
    private translate: TranslateService,
  ) {}




  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        
     
       
      });
    });

    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
    
    });
    forkJoin([
      this.getAllListFormeJuridique(),
      this.getAllListSousSecteur(),
      this.getNationalities(),
      this.getAllListGouvernorats(),
      this.getAllListDelegation(),
      this.getAllCodePostal(),
      this.getAllListSecteur(),
      this.listDiplomeSpecialite()
    ]).subscribe(() => {
      this.getInformationsSpecifique();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['demandeType']) {
      this.isEpfExistant=changes['demandeType'].currentValue;
    }
  }
  
  
  getAllListFormeJuridique() {
    return this.ApiAdminbpmService.getAllListFormeJuridique()
      .pipe(
        catchError((error) => {
          console.error('Error fetching formes:', error);
          return of([]);
        }),
        map((response) => {
          if (Array.isArray(response.data)) {
            this.formes = response.data.map((res: any) => ({
              text: res.name,
              value: res.value,
            }));
          } else {
            this.formes = [];
          }
        })
      );
  }
  
  getAllListDelegation() {
    return this.ApiAdminbpmService.getAllListDelegation()
      .pipe(
        catchError((error) => {
          console.error('Error fetching delegations:', error);
          return of([]);
        }),
        map((response) => {
          if (Array.isArray(response)) {
            this.delegation = response.map((res: any) => ({
              text: res.name,
              value: res.value,
            }));
          } else if (Array.isArray(response.data)) {
            this.delegation = response.data.map((res: any) => ({
              text: res.name,
              value: res.value,
            }));
          } else {
            console.error('Unexpected response structure:', response);
            this.delegation = [];
          }
        })
      );
  }
  
  getAllCodePostal() {
    return this.ApiAdminbpmService.getAllCodePostal()
      .pipe(
        catchError((error) => {
          console.error('Error fetching codePostals:', error);
          return of([]);
        }),
        map((response) => {
          if (Array.isArray(response)) {
            this.codePostal = response.map((res: any) => ({
              text: res.code,
              value: res.code,
            }));
          } else if (Array.isArray(response.data)) {
            this.codePostal = response.data.map((res: any) => ({
              text: res.code,
              value: res.code,
            }));
          } else {
            console.error('Unexpected response structure:', response);
            this.codePostal = [];
          }
        })
      );
  }

  listDiplomeSpecialite() {
    this.page = 1;
    this.limit = 10000;
  
    return this.ApiAdminbpmService.getListDiplomeSpecialite(this.page, this.limit, this.search)
      .pipe(
        map((response: any) => {
          if (!Array.isArray(response.data)) {
            throw new Error('Unexpected API response format');
          }
          return response.data.map((res: any) => ({
            text: res.diplome,
            value: res.specialite,
          }));
        }),
        catchError((error) => {
          console.error('Error fetching diplome_specialite:', error);
          this.alertError('Failed to load data');
          return of([]);
        }),
        map((mappedData) => {
          this.diplome_specialite = mappedData;
          this.totalLength = mappedData.length;
          this.calculate_nb_pages(this.totalLength);
        })
      );
  }
  
  getAllListSecteur() {
    return this.ApiAdminbpmService.getAllListSecteur()
      .pipe(
        map((response) => {
          this.secteurs = response.data.map((res: any) => ({
            text: res.name,
            value: res.value,
          }));
        })
      );
  }
  
  getAllListSousSecteur() {
    return this.ApiAdminbpmService.getAllListSousSecteur()
      .pipe(
        map((response) => {
          this.Soussecteurs = response.data.map((res: any) => ({
            text: res.name,
            value: res.value,
          }));
        })
      );
  }
  
  getAllListGouvernorats() {
    return this.ApiAdminbpmService.getAllListGouvernorats()
      .pipe(
        catchError((error) => {
          console.error('Error fetching goverments:', error);
          return of([]);
        }),
        map((response) => {
          if (Array.isArray(response)) {
            this.goverments = response.map((res: any) => ({
              text: res.name,
              value: res.value,
            }));
          } else if (Array.isArray(response.data)) {
            this.goverments = response.data.map((res: any) => ({
              text: res.name,
              value: res.value,
            }));
          } else {
            this.goverments = [];
          }
        })
      );
  }
  
  getNationalities() {
    return this.ApiAdminbpmService.getAllNationalite()
      .pipe(
        catchError((error) => {
          console.error('Error fetching nationalities:', error);
          return of([]);
        }),
        map((response) => {
          if (Array.isArray(response)) {
            this.nationalities = response.map((res: any) => ({
              text: res.name,
              value: res.value,
            }));
          } else if (Array.isArray(response.data)) {
            this.nationalities = response.data.map((res: any) => ({
              text: res.name,
              value: res.value,
            }));
          } else {
            this.nationalities = [];
          }
        })
      );
  }
  


  setFileValues = (value) => {
    const fileType = mime.getType(value.name);
    const file = {
      content: value.content,
      name: value.name,
      type: fileType,
    };
    return file;
  };

  setUserData = (userData, role) => {
    const fieldsToSet = [
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_passeport' : 'investor_passport',
        userDataField: 'passport',
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_experience_years' : '',
        userDataField: 'experience_years',
        readOnly: true,
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_first_name' : 'investor_first_name_ar',
        userDataField: 'prenom_ar',
        readOnly: true,
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_last_name' : 'investor_last_name_ar',
        userDataField: 'nom_ar',
        readOnly: true,
      },
      {
        surveyField:
          role === 'directeur'
            ? 'establishment_director_date_expiration_passeport'
            : 'investor_date_expiration_passeport',
        userDataField: 'expiration_passeport',
      },

      {
        surveyField: role === 'directeur' ? 'establishment_director_data_first_name_fr' : 'investor_last_name_fr',
        userDataField: 'prenom',
        readOnly: true,
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_last_name_fr' : 'investor_first_name_fr',
        userDataField: 'nom',
        readOnly: true,
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_email' : 'investor_email',
        userDataField: 'mail',
        readOnly: true,
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_birth_place' : 'investor_birth_date',
        userDataField: 'date_naiss_user',
        readOnly: true,
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_genre' : 'investor_civility',
        userDataField: 'civilite',
        readOnly: true,
      },

      {
        surveyField: role === 'directeur' ? 'establishment_director_data_nationality' : 'investor_nationality',
        userDataField: 'nationality',
        readOnly: true,
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_cin_number' : 'investor_cin',
        userDataField: 'cin',
        readOnly: true,
      },

      {
        surveyField: role === 'directeur' ? 'establishment_director_data_date_delivrance' : 'investor_date_delivrance',
        userDataField: 'delivrance_date',
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_birth_place' : 'investor_birth_place',
        userDataField: 'birthplace',
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_education_level' : 'investor_eduction_level',
        userDataField: 'niveau_scolaire',
        readOnly: true,
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_phone_number' : 'investor_phone_number',
        userDataField: 'telephone',
        readOnly: true,
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_address' : 'investor_residence_address',
        userDataField: 'adresse',
      },
      {
        surveyField:
          role === 'directeur' ? 'establishment_director_data_governorate' : 'investor_residence_governorate',
        userDataField: 'goverment',
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_delegation' : 'investor_residence_delegation',
        userDataField: 'delegation',
      },
      {
        surveyField: role === 'directeur' ? '' : 'investor_postal_code',
        userDataField: 'code_postal',
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_specific_needs' : 'investor_specific_needs',
        userDataField: 'besoins_specifiques',
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_cin_copie' : 'investor_copie_cin',
        userDataField: 'copie_cin',
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_passeport_copie' : 'investor_copie_passeport',
        userDataField: 'copie_passeport',
      },
      {
        surveyField: role === 'directeur' ? 'establishment_director_data_director_folder' : 'investor_copie_cv',
        userDataField: 'copie_cv',
      },
      {
        surveyField: role === 'directeur' ? 'copie_autorisation_ministre' : '',
        userDataField: 'copie_autorisation_ministre',
      },
    ];

    fieldsToSet.forEach((field) => {
      const { surveyField, userDataField, readOnly } = field;
    
      if (!userData) {
        
        return;
      }
    
      const value =
        surveyField.includes('copie') && userData[userDataField]
          ? this.setFileValues(userData[userDataField])
          : surveyField.includes('_date') && userData[userDataField]
          ? moment(userData[userDataField]).format('YYYY-MM-DD')
          : userData[userDataField] || null;
    
      const question = this.surveyModel.getQuestionByName(surveyField);
    
      if (!question) {
        return;
      }
    
      if (!value) {
        question.readOnly = true;
      } else {
        this.surveyModel.setValue(surveyField, value);
        if (readOnly && question.readOnly) {
          question.readOnly = true;
        }
      }
    });
  };

  getUserInfo(id_user: string, isRecursiveCall: boolean = false): Promise<void> {
    this.loading = true;

    return new Promise((resolve, reject) => {
      const subscription = this.apiAffairesService.getInfoUser(id_user).pipe(
        catchError((error) => {
          this.alertError('Failed to fetch user information.');
          this.loading = false;
          reject(error);
          return of(null);
        })
      );
    });
  }
  
  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

 
 


  getInformationsSpecifique(): void {
    this.loading = true;
  
    this.apiAffairesService.getInfoSpecifiqueAffaireGet(this.affaire_id).subscribe(
      (data: any) => {
        if (!data?.response) {
          console.error('Invalid response data');
          this.loading = false;
          this.formLoaded.emit(true);
          return;
        }

if (data.response.id_affaire_parent){
      this.apiAffairesService.getInfoSpecifiqueAffaireGet(data.response.id_affaire_parent).subscribe(
        (dataparent: any) => {
          if (!data?.response) {
            console.error('Invalid response data');
            this.loading = false;
            this.formLoaded.emit(true);
            return;
          }

      let currentData = { ...data.response.data };
      let currentDataParent = { ...dataparent.response.data };
      // Process the key_urls and update the data
      if (Array.isArray(data.response.key_urls)) {
        data.response.key_urls.forEach((item) => {
          const fileType = mime.getType(item.name_doc) || 'application/octet-stream';
          const key = item.key;
          const dynamicPanelMatch = key.match(/^(.*?)\[(\d+)\]\.(.*)$/);

          if (dynamicPanelMatch) {
            const panelName = dynamicPanelMatch[1];
            const panelIndex = parseInt(dynamicPanelMatch[2], 10);
            const fieldName = dynamicPanelMatch[3];
            if (!currentData[panelName]) {
              currentData[panelName] = [];
            }
            if (!currentData[panelName][panelIndex]) {
              currentData[panelName][panelIndex] = {};
            }
            currentData[panelName][panelIndex][fieldName] = [
              {
                content: item.url,
                name: item.name_doc,
                type: fileType,
              },
            ];
          } else {
            currentData[key] = [
              {
                content: item.url,
                name: item.name_doc,
                type: fileType,
              },
            ];
          }

          // Add document to management data
          if (currentData['management_data']) {
            currentData['management_data'].forEach((e) => {
              e[item.key] = [
                {
                  content: item.url,
                  name: item.name_doc,
                  type: fileType,
                },
              ];
            });
          }
        });
      }

      // Update the response data with processed data
      data.response.data = currentData;
      this.suveyInfo = data.response;



      if (Array.isArray(dataparent.response.key_urls)) {
        dataparent.response.key_urls.forEach((item) => {
          const fileType = mime.getType(item.name_doc) || 'application/octet-stream';
          const key = item.key;
          const dynamicPanelMatch = key.match(/^(.*?)\[(\d+)\]\.(.*)$/);

          if (dynamicPanelMatch) {
            const panelName = dynamicPanelMatch[1];
            const panelIndex = parseInt(dynamicPanelMatch[2], 10);
            const fieldName = dynamicPanelMatch[3];
            if (!currentDataParent[panelName]) {
              currentDataParent[panelName] = [];
            }
            if (!currentDataParent[panelName][panelIndex]) {
              currentDataParent[panelName][panelIndex] = {};
            }
            currentDataParent[panelName][panelIndex][fieldName] = [
              {
                content: item.url,
                name: item.name_doc,
                type: fileType,
              },
            ];
          } else {
            currentDataParent[key] = [
              {
                content: item.url,
                name: item.name_doc,
                type: fileType,
              },
            ];
          }

          // Add document to management data
          if (currentDataParent['management_data']) {
            currentDataParent['management_data'].forEach((e) => {
              e[item.key] = [
                {
                  content: item.url,
                  name: item.name_doc,
                  type: fileType,
                },
              ];
            });
          }
        });
      }

      // Update the response data with processed data
      dataparent.response.data = currentDataParent;


      // Initialize survey based on the returned data
      if (this.isEpfExistant === 'epf-existant' ) {
        const mockData = require('src/assets/json-schema/EPFexistantSchema.json');
        this.surveyJsInit(mockData, this.suveyInfo.data ,currentDataParent);
      } else {
        this.surveyJsInit(this.suveyInfo?.form, this.suveyInfo.data ,currentDataParent);
      }

      // Handle user and responsible EPF data directly from the response
      const { id_user, responsable_EPF } = this.suveyInfo;

      if (!id_user) {
        console.error('No user ID found in info spécifique response');
      } else {
        this.setUserData(this.suveyInfo.user, '');

        // Check if responsable_EPF exists and set the user data for it as well
        if (this.informationsContrat?.contrat?.etat_dossier_id === environment.valide_statut_id && responsable_EPF) {
          this.setUserData(this.suveyInfo.responsable_EPF, 'directeur');
        }
      }

      this.loading = false;
      this.formLoaded.emit(true);
    },
    (error) => {
      console.error('Error in getInformationsSpecifique:', error);
      this.loading = false;
      this.formLoaded.emit(true);
    })
}else{
        let currentData = { ...data.response.data };
      
        // Process the key_urls and update the data
        if (Array.isArray(data.response.key_urls)) {
          data.response.key_urls.forEach((item) => {
            const fileType = mime.getType(item.name_doc) || 'application/octet-stream';
            const key = item.key;
            const dynamicPanelMatch = key.match(/^(.*?)\[(\d+)\]\.(.*)$/);

            if (dynamicPanelMatch) {
              const panelName = dynamicPanelMatch[1];
              const panelIndex = parseInt(dynamicPanelMatch[2], 10);
              const fieldName = dynamicPanelMatch[3];
              if (!currentData[panelName]) {
                currentData[panelName] = [];
              }
              if (!currentData[panelName][panelIndex]) {
                currentData[panelName][panelIndex] = {};
              }
              currentData[panelName][panelIndex][fieldName] = [
                {
                  content: item.url,
                  name: item.name_doc,
                  type: fileType,
                },
              ];
            } else {
              currentData[key] = [
                {
                  content: item.url,
                  name: item.name_doc,
                  type: fileType,
                },
              ];
            }

            // Add document to management data
            if (currentData['management_data']) {
              currentData['management_data'].forEach((e) => {
                e[item.key] = [
                  {
                    content: item.url,
                    name: item.name_doc,
                    type: fileType,
                  },
                ];
              });
            }
          });
        }

        // Update the response data with processed data
        data.response.data = currentData;
        this.suveyInfo = data.response;
      if (this.isEpfExistant === 'epf-existant' ) {
        const mockData = require('src/assets/json-schema/EPFexistantSchema.json');
        this.surveyJsInit(mockData, this.suveyInfo.data );
      } else {
        this.surveyJsInit(this.suveyInfo?.form, this.suveyInfo.data );
      }

      // Handle user and responsible EPF data directly from the response
      const { id_user, responsable_EPF } = this.suveyInfo;

      if (!id_user) {
        console.error('No user ID found in info spécifique response');
      } else {
        this.setUserData(this.suveyInfo.user, '');

        // Check if responsable_EPF exists and set the user data for it as well
        if (this.informationsContrat?.contrat?.etat_dossier_id === environment.valide_statut_id && responsable_EPF) {
          this.setUserData(this.suveyInfo.responsable_EPF, 'directeur');
        }
      }

      this.loading = false;
      this.formLoaded.emit(true);
      }
            
      
    },

      (error) => {
        console.error('Error in getInformationsSpecifique:', error);
        this.loading = false;
        this.formLoaded.emit(true);
      }
    );
  }

  
  ngOnDestroy(): void {
    this.ListeofUnsbscribeservice.forEach((element) => {
      element?.unsubscribe();
    });
  }



  highlightDifferencesInInputs(
    parentValue: any,
    childValue: any,
    containerElement: HTMLElement
  ): void {
    if (Array.isArray(parentValue) && Array.isArray(childValue)) {
      let differencesFound = false;
      parentValue.forEach((parentItem, index) => {
        const childItem = childValue[index] || {}; 
        Object.keys(parentItem).forEach((key) => {
          if (!this.deepEqual(parentItem[key], childItem[key])) {
            differencesFound = true;
            const inputElement = containerElement.querySelector(
              `[data-name="${key}"]`
            );
            if (inputElement && inputElement instanceof HTMLElement) {
              this.applyHighlight(inputElement);
              this.addCustomTooltip(
                inputElement as HTMLElement,
                `Ancienne valeur : <b>${parentItem[key]}</b>`
              );
            }
          }
        });
      });
      if (differencesFound) {
        this.applyHighlight(containerElement);
      }
    } else if (
      typeof parentValue === "object" &&
      typeof childValue === "object"
    ) {
      let differencesFound = false;
      Object.keys(parentValue).forEach((key) => {
        if (!this.deepEqual(parentValue[key], childValue[key])) {
          differencesFound = true;
          const inputElement = containerElement.querySelector(
            `[data-name="${key}"]`
          );
          if (inputElement && inputElement instanceof HTMLElement) {
            this.applyHighlight(inputElement);
            this.addCustomTooltip(
              inputElement as HTMLElement,
              `Ancienne valeur : <b>${parentValue[key]}</b>`
            );
          }
        }
      });
      if (differencesFound) {
        this.applyHighlight(containerElement);
      }
    } else {
      if (parentValue !== childValue) {
        this.applyHighlight(containerElement);
        this.addCustomTooltip(
          containerElement,
          `Ancienne valeur : <b>${parentValue}</b>`
        );
      }
    }
  }

  addCustomTooltip(element: HTMLElement, tooltipContent: string): void {
    // Create tooltip element
    const tooltip = document.createElement("div");
    tooltip.innerHTML = tooltipContent;
  tooltip.style.position = "absolute";
  tooltip.style.backgroundColor = "#f9f9f9";
  tooltip.style.border = "1px solid #ccc";
  tooltip.style.padding = "5px 10px";
  tooltip.style.borderRadius = "8px";
  tooltip.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
  tooltip.style.zIndex = "1000";
  tooltip.style.display = "none";
  tooltip.style.whiteSpace = "nowrap";
  document.body.appendChild(tooltip);
  
  element.addEventListener("mouseenter", (event) => {
    const rect = element.getBoundingClientRect();
    tooltip.style.left = `${rect.left + window.scrollX}px`; // Align with the input's left edge
    tooltip.style.top = `${rect.bottom + window.scrollY + 5}px`; // Position just below the input with a small gap
    tooltip.style.display = "block";
  });

  // Hide tooltip on mouseleave
  element.addEventListener("mouseleave", () => {
    tooltip.style.display = "none";
  });
  }

  
  deepEqual(obj1: any, obj2: any): boolean {
    // Perform deep equality check for objects and arrays
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      return (
        obj1.length === obj2.length &&
        obj1.every((item, index) => this.deepEqual(item, obj2[index]))
      );
    } else if (typeof obj1 === "object" && typeof obj2 === "object") {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
  
      if (keys1.length !== keys2.length) {
        return false;
      }
  
      return keys1.every((key) => this.deepEqual(obj1[key], obj2[key]));
    }
  
    return obj1 === obj2;
  }
  
  applyHighlight(element: HTMLElement): void {
    element.style.backgroundColor = "#D6F0DD"; 
    element.style.border = "1px solid #31B255"; 
    element.style.padding = "10px";
    element.style.borderRadius = "15px";
  }
  surveyJsInit(surveyJSON: any, data: any , dataParent?:any): void {
    try {
      this.surveyModel = new SurveyModel(surveyJSON);
      console.log(data)
      console.log(dataParent)
      this.surveyModel.data = data;

      this.displayQuestionsFunction();


      


      if (data.inverstor_type === 'شخص طبيعي' && this.surveyModel.getPageByName('legal_entity_page')){
this.surveyModel.getPageByName('legal_entity_page').visible = false;
      }
      const modifyFileLinks = (element: HTMLElement) => {
        const fileLinks = element.querySelectorAll('a');
        fileLinks.forEach((link) => {
          link.setAttribute('target', '_blank');
        });
      };

      const observeFileQuestion = (element: HTMLElement) => {
        const observer = new MutationObserver((mutationsList) => {
          for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
              modifyFileLinks(element);
            }
          }
        });
        observer.observe(element, { childList: true, subtree: true });
        modifyFileLinks(element);
      };

      this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
        if (options.question.getType() === 'file') {
          observeFileQuestion(options.htmlElement);
        }
      });

      this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
        const placeholderNoFile = options.htmlElement.querySelector('.sd-file__decorator');
        if (placeholderNoFile) {
          const placeholder = placeholderNoFile.querySelector('.sd-file__drag-area-placeholder');
          if (placeholder) {
            const spanElement = placeholder.querySelector('.sv-string-viewer');
            if (spanElement && spanElement.textContent === 'No file selected') {
              spanElement.textContent = 'Aucun fichier téléchargé - لم يتم تحميل أي ملف';
            }
          }
        }

        if (options.question.getType() === 'file') {
          observeFileQuestion(options.htmlElement);
        }
      });

      this.surveyModel.onAfterRenderPanel.add((sender, options) => {
        options.panel.questions.forEach((question) => {
          if (question.getType() === 'file') {
            const questionElement = options.htmlElement.querySelector(`[name='${question.inputName}']` );
            if (questionElement) {
              observeFileQuestion(questionElement.closest('.sv_q'));
            }
          }
        });
      });

      this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
        if (options.question.name === 'trainer_data') {
          options.question.panels.forEach((panel) => {
            const nationalityQuestion = panel.getQuestionByName('trainer_data_nationality');
            const nationality = nationalityQuestion?.value;

            const expirationDateQuestion = panel.getQuestionByName('trainer_date_expiration_passeport');
            if (expirationDateQuestion && nationality === 'تونسية') {
              expirationDateQuestion.visible = false;
            } else if (expirationDateQuestion && nationality !== 'تونسية') {
              expirationDateQuestion.visible = true;
              expirationDateQuestion.value =moment(expirationDateQuestion.value).format('YYYY-MM-DD')
            }
          });
        }
      });

      this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
        if (options.question.name === 'training_nature_data') {
          options.question.panels.forEach((panel) => {
            panel.questions.forEach((question) => {
              if (question.name === 'training_type_data_sector') {
                question.choices = this.secteurs;
              }
              if (question.name === 'training_type_data_sub_sector') {
                question.choices = this.Soussecteurs;
              }

              if (question.name === 'training_type_data_specialty') {
                question.choices = this.diplome_specialite;
              }
            });
          });
        }
      });

      this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
        if (options.question.name === 'trainer_data') {
          options.question.panels.forEach((panel) => {
            panel.questions.forEach((question) => {
              if (question.name.toLowerCase().includes('_nationality')) {
                question.choices = this.nationalities;
              }
              if (question.name.toLowerCase().includes('_governorate')) {
                question.choices = this.goverments;
              }
              if (question.name.toLowerCase().includes('_date')) {
                this.surveyModel.setValue(question.name, moment(question.value).format('YYYY-MM-DD'));
              }
              if (question.name.toLowerCase().includes('_delegation')) {
                question.choices = this.delegation;
              }
              if (question.name.toLowerCase().includes('_postal_code')) {
                question.choices = this.codePostal;
                
              }
            });
          });
        }
      });

      this.surveyModel.onDynamicPanelAdded.add((sender, options) => {
        if (options.panel.questions) {
          options.panel.questions.forEach((question) => {
            if (question.name === 'management_data_staff_nationality') {
              question.choices = this.nationalities;
            }
          });
        }
      });

      this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
        if (options.question.name === 'establishment_data_tax_number') {
          options.question.readOnly = true;
        }
      });

      this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
        if (options.question.name === 'establishment_data_reservation_number_commercial') {
          options.question.readOnly = true;
        }
      });

      this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
        if (options.question.name === 'legal_entity_head_office_address') {
          options.question.startWithNewLine = true;
        }
      });

      this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
        if (options.question.name === 'establishment_director_data_nationality') {
          options.question.startWithNewLine = true;
        }
      });

      this.surveyModel.getAllQuestions().forEach((question) => {
        if (question.name === 'establishment_director_data_test_valid') {
          question.visible = false;
          question.visibleIf = null;
        }

        const legalEntityQuestion = this.surveyModel.getQuestionByName('legal_entity_legal_form');
        if (legalEntityQuestion) {
          legalEntityQuestion.choices = this.formes;
        }

        if (question.name === 'legal_entity_legal_form') {
          question.choices = this.formes;
        }

        const rneDescriptionQuestion = this.surveyModel.getQuestionByName('legal_entity_rne_description');
        if (rneDescriptionQuestion) {
          rneDescriptionQuestion.visible = false;
          rneDescriptionQuestion.visibleIf = null;
        }

        if (question.name === 'management_data') {
          const dynamicPanel = question as QuestionPanelDynamicModel;
          dynamicPanel.panels.forEach((panel, index) => {
            const managementData = data['management_data'][index];
            if (managementData) {
              // Preload files for Tunisian nationals
              if (managementData['management_data_staff_nationality'] === 'تونسية') {
                const cinCopy = managementData['management_data_staff_cin_copy'];
                if (cinCopy) {
                  const fileArray = Array.isArray(cinCopy) ? cinCopy : [cinCopy];
                  panel.getQuestionByName('management_data_staff_cin_copy').value = fileArray;
                  panel.getQuestionByName('management_data_staff_cin_copy').visible = true;
                }
              } else {
                // Preload files for non-Tunisian nationals
                const passportCopy = managementData['management_data_staff_passport_copy'];
                if (passportCopy) {
                  const fileArray = Array.isArray(passportCopy) ? passportCopy : [passportCopy];
                  panel.getQuestionByName('management_data_staff_passport_copy').value = fileArray;
                  panel.getQuestionByName('management_data_staff_passport_copy').visible = true;
                }
              }

              // Preload the personal folder file
              const personalFolder = managementData['management_data_staff_personal_folder'];
              if (personalFolder) {
                const fileArray = Array.isArray(personalFolder) ? personalFolder : [personalFolder];
                panel.getQuestionByName('management_data_staff_personal_folder').value = fileArray;
                panel.getQuestionByName('management_data_staff_personal_folder').visible = true;
              }
            }
          });
        }

        if (question.name === 'trainer_data') {
          const dynamicPanel = question as QuestionPanelDynamicModel;
          dynamicPanel.panels.forEach((panel, index) => {
            const trainerData = data['trainer_data'][index];
            if (trainerData) {
              const trainerNationality = trainerData['trainer_data_nationality'];
              const isTunisian = trainerNationality === 'تونسية';

              const idNumber = isTunisian
                ? trainerData['trainer_data_cin_number']
                : trainerData['trainer_data_passport'];

              this.apiAffairesService
                .getDocumentFormateurByPassAndCin(isTunisian ? null : idNumber, isTunisian ? idNumber : null)
                .subscribe((res) => {
                  const documents = res?.response;

                  if (documents && documents.length > 0) {
                    if (isTunisian) {
                      const responseFile = documents.find((e: any) => e.key === 'copie_cin');
                      if (responseFile) {
                        const file = [
                          {
                            content: responseFile.url,
                            name: responseFile.name,
                            type: mime.getType(responseFile.name) || 'application/octet-stream',
                          },
                        ];
                        panel.getQuestionByName('trainer_data_cin').value = file;
                        panel.getQuestionByName('trainer_data_cin').visible = true;
                      }
                    } else {
                      const responseFile = documents.find((e: any) => e.key === 'copie_passeport');
                      if (responseFile) {
                        const file = [
                          {
                            content: responseFile.url,
                            name: responseFile.name,
                            type: mime.getType(responseFile.name) || 'application/octet-stream',
                          },
                        ];
                        panel.getQuestionByName('trainer_data_passeport').value = file;
                        panel.getQuestionByName('trainer_data_passeport').visible = true;
                      }
                    }

                    
                    const responseFile = documents.find((e: any) => e.key === 'dossier_file');
                    if (responseFile) {
                      const file = [
                        {
                          content: responseFile.url,
                          name: responseFile.name,
                          type: mime.getType(responseFile.name) || 'application/octet-stream',
                        },
                      ];
                      panel.getQuestionByName('trainer_data_folder').value = file;
                    }
                    
                  }
                });
            }
          });
        }
      });

      this.surveyModel.getAllQuestions().forEach((question) => {
        if (question.name === 'management_data_staff_category_other_text') {
          question.visible = false;
        }

        if (question.name === 'trainer_data') {
          question.panels.forEach((panel) => {
            panel.getQuestions().forEach((panelQuestion) => {
              if (
                panelQuestion.name === 'trainer_data_birth_date' ||
                panelQuestion.name === 'trainer_data_cin_date_delivrance'
              ) {
                const birthDate = panelQuestion.value;
                if (birthDate) {
                  panelQuestion.value = moment(birthDate).format('YYYY-MM-DD');
                }
              }
            });
          });
        }

        const descriptionLegalQuestion = this.surveyModel.getQuestionByName('legal_entity_rne_description');
        if (descriptionLegalQuestion) {
          descriptionLegalQuestion.visible = false;
        }

        const descriptionQuestion = this.surveyModel.getQuestionByName('establishment_rne_description');
        if (descriptionQuestion) {
          descriptionQuestion.visible = false;
          descriptionQuestion.visibleIf = null;
        }

        if (question.name.toLowerCase().includes('_nationality')) {
          question.choices = this.nationalities;
        }

        if (question.name === 'training_nature_data') {
          question.panels.forEach((panel) => {
            panel.questions.forEach((question) => {
              if (question.name === 'training_type_data_sector') {
                question.choices = this.secteurs;
              }
              if (question.name === 'training_type_data_sub_sector') {
                question.choices = this.Soussecteurs;
              }

              if (question.name === 'training_type_data_specialty') {
                question.choices = this.diplome_specialite;
              }
            });
          });
        }

        if (question.name === 'trainer_data') {
          question.panels.forEach((panel) => {
            panel.questions.forEach((question) => {
              if (question.name.toLowerCase().includes('_nationality')) {
                question.choices = this.nationalities;
              }
              if (question.name.toLowerCase().includes('_governorate')) {
                question.choices = this.goverments;
              }
              if (question.name.toLowerCase().includes('_date')) {
                this.surveyModel.setValue(question.name, moment(question.value).format('YYYY-MM-DD'));
              }
              if (question.name.toLowerCase().includes('_delegation')) {
                question.choices = this.delegation;
              }
              if (question.name.toLowerCase().includes('postal_code')) {
                question.choices = this.codePostal;
              }
            });
          });
        }

        if (question.name === 'management_data') {
          question.panels.forEach((panel) => {
            panel.questions.forEach((question) => {
              if (
                question.name === 'management_data_staff_birth_date' ||
                question.name === 'management_data_staff_date_delivrance'
              ) {
                const birthDate = question.value;

                if (birthDate) {
                  question.value = moment(birthDate).format('YYYY-MM-DD');
                }
              }

              if (question.name.toLowerCase().includes('_governorate')) {
                question.choices = this.goverments;
              }
              if (question.name.toLowerCase().includes('_date')) {
                this.surveyModel.setValue(question.name, moment(question.value).format('YYYY-MM-DD'));
              }
              if (question.name.toLowerCase().includes('_delegation')) {
                question.choices = this.delegation;
              }
              if (question.name.toLowerCase().includes('_nationality')) {
                question.choices = this.nationalities;
              }
              if (question.name.toLowerCase().includes('postal_code')) {
                question.choices = this.codePostal;
              }
            });
          });
        }

        if (
          question.name === 'file_description' ||
          question.name === 'legal_entity_rne_description' ||
          question.name === 'establishment_rne_description' ||
          question.name === 'espace_file_description'
        ) {
          question.visible = false;
        }

        if (question.name.toLowerCase().includes('_governorate')) {
          question.choices = this.goverments;
        }
        if (question.name.toLowerCase().includes('_date')) {
          this.surveyModel.setValue(question.name, moment(question.value).format('YYYY-MM-DD'));
        }
        if (question.name.toLowerCase().includes('_delegation')) {
          question.choices = this.delegation;
        }
        if (question.name.toLowerCase().includes('postal_code')) {
          question.choices = this.codePostal;
        }
      });

      // Apply the theme
      StylesManager.applyTheme('defaultV2');

      // Set survey data

      if (
        this.surveyModel?.data.regional_dir_decision &&
        this.surveyModel?.data?.regional_dir_decision_missing_pieces?.length > 0
      ) {
        this.allowUpdateStatus.emit(true);
      } else {
        this.allowUpdateStatus.emit(false);
      }


      // Configure survey navigation and progress bar
      this.surveyModel.showProgressBar = 'top';
      this.surveyModel.showPageTitles = true;
      this.surveyModel.showQuestionNumbers = 'onPage';
      this.surveyModel.showNavigationButtons = true;
      this.surveyModel.progressBarType = 'pages';

      this.surveyModel.showCompleteButton = false;
      this.surveyModel.editText = 'Consulter - الإطلاع';

      // Set all questions to read-only
      this.surveyModel.getAllQuestions().forEach((question) => {
        question.readOnly = true;
      });


      this.surveyModel.onValueChanged.add((sender: SurveyModel, options: any) => {
        const surveyData = sender.data;
        this.surveyModel.data = { ...surveyData };

        if (
          surveyData?.regional_dir_decision &&
          surveyData?.regional_dir_decision_missing_pieces?.length > 0 &&
          surveyData?.regional_dir_decision_missing_pieces?.every(
            (piece) =>
              piece?.regional_dir_decision_missing_piece && piece?.regional_dir_decision_missing_piece.trim() !== ''
          )
        ) {
          this.allowUpdateStatus.emit(true);
        } else {
          this.allowUpdateStatus.emit(false);
        }

        this.dataForm.emit({
          data: surveyData,

          model: sender.toJSON(),
        });
      });
 // Compare parent and child data and highlight differences
 this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
  const questionName = options.question.name;

  // Skip if the question doesn't exist in both data objects
  if (!dataParent || !dataParent.hasOwnProperty(questionName) || !data.hasOwnProperty(questionName)) {
    return;
  }

  const parentValue = dataParent[questionName];
  const childValue = data[questionName];

  // Handle both simple and complex data
  this.highlightDifferencesInInputs(parentValue, childValue, options.htmlElement);
});
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  displayQuestionsFunction = () => {
    const directorSearchQuestion = this.surveyModel.getQuestionByName('director_search');
    if (directorSearchQuestion) {
      directorSearchQuestion.visible = false;

      directorSearchQuestion.visibleIf = null;

      this.surveyModel.render;
    }


    const managementDataSearchQuestion = this.surveyModel.getQuestionByName('management_data_search');
    if (managementDataSearchQuestion) {
      managementDataSearchQuestion.visible = false;
      managementDataSearchQuestion.visibleIf = 'false';
    }

    this.surveyModel.getAllQuestions().forEach(async (question) => {
      if (question.name === 'management_data') {
        const dynamicPanel = question;
        dynamicPanel.panels.forEach(async (panel) => {
          panel.getQuestions().forEach((question) => {
            if (
              question.name !== 'management_data_staff_cin_number' &&
              question.name !== 'management_data_staff_passport_number' &&
              question.name !== 'management_data_staff_date_delivrance' &&
              question.name !== 'management_data_staff_cin_copy' &&
              question.name !== 'management_data_staff_passport_copy' &&
              question.name !== 'management_data_staff_valid' &&
              question.name !== 'management_data_check_spinner' &&
              question.name !== 'management_data_search'
            ) {
              question.visible = true;
            }

            if (question.name === 'management_data_search') {
              question.visible = false;
              question.visibleIf = 'false';
            }

            if (panel.getQuestionByName('management_data_staff_nationality')?.value === 'تونسية') {
              if (
                question.name === 'management_data_staff_cin_copy' ||
                question.name === 'management_data_staff_date_delivrance'
              ) {
                question.visible = true;
              }
            } else if (panel.getQuestionByName('management_data_staff_nationality')?.value !== 'تونسية') {
              if (question.name === 'management_data_staff_passport_copy') {
                question.visible = true;
              }
            }
          });
        });
      }
      if (question.name === 'trainer_data') {
        const dynamicPanel = question;

        dynamicPanel.panels.forEach(async (panel) => {
          panel.getQuestions().forEach(async (question) => {
            if (
              question.name !== 'trainer_data_cin' &&
              question.name !== 'trainer_data_cin_number' &&
              question.name !== 'trainer_data_cin_date_delivrance' &&
              question.name !== 'trainer_data_passeport' &&
              question.name !== 'trainer_data_folder' &&
              question.name !== 'trainer_data_test_valid' &&
              question.name !== 'trainer_active' &&
              question.name !== 'trainer_data_passport' &&
              question.name !== 'cin_check_spinner' &&
              question.name !== 'dossier_autoriser'
            ) {
              question.visible = true;
            }

            if (panel.getQuestionByName('dossier_autoriser')?.value === true) {
              if (question.name === 'trainer_data_folder') {
                question.visible = true;
              }
            }

            if (panel.getQuestionByName('trainer_data_nationality')?.value === 'تونسية') {
              if (question.name === 'trainer_data_cin' || question.name === 'trainer_data_cin_date_delivrance') {
                question.visible = true;
              }
            } else if (panel.getQuestionByName('trainer_data_nationality')?.value !== 'تونسية') {
              if (question.name === 'trainer_data_passeport' || question.name === 'trainer_data_passeport') {
                question.visible = true;
              }
            }
          });
        });
      }

      const page = this.surveyModel.getPageByName('Données de Directeur de l’Établissement');

      if (page) {
        const directorSearchQuestion = this.surveyModel.getQuestionByName('director_search');
        if (directorSearchQuestion) {
          directorSearchQuestion.visible = null;
          directorSearchQuestion.visible = false;
        }

        if (this.surveyModel.getValue('establishment_director_data_identity') === 'المستثمر نفسه') {
          page.questions.forEach((question) => {
            if (
              question.name !== 'establishment_director_data_test_valid' &&
              question.name !== 'establishment_director_data_passeport_copie' &&
              question.name !== 'copie_autorisation_ministre' &&
              question.name !== 'establishment_director_data_cin_copie' &&
              question.name !== 'establishment_director_data_date_delivrance' &&
              question.name !== 'establishment_director_data_ministerial_auth_date' &&
              question.name !== 'establishment_director_data_passeport' &&
              question.name !== 'establishment_director_data_cin_number' &&
              question.name !== 'establishment_director_data_spinner' &&
              question.name !== 'director_search'
            ) {
              question.visible = true;
            }
            if (this.surveyModel.getQuestionByName('investor_nationality')?.value === 'تونسية') {
              if (
                question.name === 'establishment_director_data_cin_copie' ||
                question.name === 'establishment_director_data_date_delivrance'
              ) {
                question.visible = true;
              }
            } else if (this.surveyModel.getQuestionByName('investor_nationality')?.value !== 'تونسية') {
              if (
                question.name === 'establishment_director_data_test_valid' ||
                question.name === 'establishment_director_data_passeport_copie' ||
                question.name === 'copie_autorisation_ministre' ||
                question.name === 'establishment_director_data_ministerial_auth_date'
              ) {
                question.visible = true;
              }
            }
          });
        } else {
          if (question.name === 'establishment_director_data_nationality') {
            const isTunisian = question.value === 'تونسية';

            const tunisianVisibleQuestions = [
              'establishment_director_data_nationality',
              'establishment_director_data_identity',
              'establishment_director_data_cin_number',
              'establishment_director_data_birth_date',
              // "director_search",
            ];

            const nonTunisianQuestions = [
              'establishment_director_data_test_valid',
              'establishment_director_data_passeport_copie',
              'copie_autorisation_ministre',
              'establishment_director_data_ministerial_auth_date',
              'establishment_director_data_passeport',
            ];

            const alwaysVisibleQuestions = [
              'establishment_director_data_test_valid',
              'establishment_director_data_passeport_copie',
              'copie_autorisation_ministre',
              'establishment_director_data_cin_copie',
              'establishment_director_data_date_delivrance',
              'establishment_director_data_ministerial_auth_date',
              'establishment_director_data_passeport',
              'establishment_director_data_cin_number',
              'establishment_director_data_spinner',
              // "director_search",
            ];

            const page = this.surveyModel.getPageByName('Données de Directeur de l’Établissement');

            page.questions.forEach((question) => {
              question.visible = false;

              if (isTunisian) {
                question.visible = tunisianVisibleQuestions.includes(question.name);
              } else {
                question.visible = nonTunisianQuestions.includes(question.name);
              }

              if (!alwaysVisibleQuestions.includes(question.name) && !isTunisian) {
                question.visible = true;
              }
            });
          }

          page.questions.forEach((question) => {
            if (
              question.name !== 'establishment_director_data_test_valid' &&
              question.name !== 'establishment_director_data_passeport_copie' &&
              question.name !== 'copie_autorisation_ministre' &&
              question.name !== 'establishment_director_data_cin_copie' &&
              question.name !== 'establishment_director_data_date_delivrance' &&
              question.name !== 'establishment_director_data_ministerial_auth_date' &&
              question.name !== 'establishment_director_data_passeport' &&
              question.name !== 'establishment_director_data_cin_number' &&
              question.name !== 'establishment_director_data_spinner' &&
              question.name !== 'director_search'
            ) {
              question.visible = true;
            }
            if (this.surveyModel.getQuestionByName('establishment_director_data_nationality')?.value === 'تونسية') {
              if (
                question.name === 'establishment_director_data_cin_copie' ||
                question.name === 'establishment_director_data_date_delivrance'
              ) {
                question.visible = true;
              }
            } else if (
              this.surveyModel.getQuestionByName('establishment_director_data_nationality')?.value !== 'تونسية'
            ) {
              if (
                question.name === 'establishment_director_data_test_valid' ||
                question.name === 'establishment_director_data_passeport_copie' ||
                question.name === 'copie_autorisation_ministre' ||
                question.name === 'establishment_director_data_ministerial_auth_date'
              ) {
                question.visible = true;
              }
            }
          });
        }
      }
    });

    // if (this.informationsContrat?.contrat?.etat_dossier_id === environment.valide_statut_id) {
    //   this.surveyModel.mode = 'display';

    //   this.surveyModel.showNavigationButtons = false;
    //   this.surveyModel.showProgressBar = 'off';

    //   this.surveyModel.showEditButton = false;

    //   this.surveyModel.pages.forEach((page) => {

    //     page.visible = true;
    //   });

    //   this.surveyModel.getAllQuestions().forEach((question) => {
    //     question.readOnly = true;
    //   });

    //   this.surveyModel.isSinglePage = true;

    //   const modifyFileLinks = (element: HTMLElement) => {
    //     const fileLinks = element.querySelectorAll('a');
    //     fileLinks.forEach((link) => {
    //       link.setAttribute('target', '_blank');
    //     });
    //   };

    //   const observeFileQuestion = (element: HTMLElement) => {
    //     const observer = new MutationObserver((mutationsList) => {
    //       for (const mutation of mutationsList) {
    //         if (mutation.type === 'childList') {
    //           modifyFileLinks(element);
    //         }
    //       }
    //     });
    //     observer.observe(element, { childList: true, subtree: true });
    //     modifyFileLinks(element);
    //   };

    //   this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
    //     const placeholderNoFile = options.htmlElement.querySelector('.sd-file__decorator');
    //     if (placeholderNoFile) {
    //       const placeholder = placeholderNoFile.querySelector('.sd-file__drag-area-placeholder');
    //       if (placeholder) {
    //         const spanElement = placeholder.querySelector('.sv-string-viewer');
    //         if (spanElement && spanElement.textContent === 'No file selected') {
    //           spanElement.textContent = 'Aucun fichier téléchargé - لم يتم تحميل أي ملف';
    //         }
    //       }
    //     }

    //     if (options.question.getType() === 'file') {
    //       observeFileQuestion(options.htmlElement);
    //     }
    //   });

    //   this.surveyModel.onAfterRenderPanel.add((sender, options) => {
    //     options.panel.questions.forEach((question) => {
    //       if (question.getType() === 'file') {
    //         const questionElement = options.htmlElement.querySelector(`[name='${question.inputName}']`);
    //         if (questionElement) {
    //           observeFileQuestion(questionElement.closest('.sv_q'));
    //         }
    //       }
    //     });
    //   });
    // }

  };

  alertSuccess(data) {
    swal.fire({
      title: 'Opération Réussie!',
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#68a45b',
    });
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      text: 'Il faut remplir tout les champs!',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }
}

// last page and dynamic pannel
