import { environment } from './../../../../environments/environment';
import { NotificationsService } from './../../../shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from './../../../entity/NotificationMessage';
import { ApiDocumentsService } from './../../../services/ApiDocuments/api-documents.service';
/* eslint-disable dot-notation */
import { BankInformation } from './../../../entity/BankInformation';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { Router, ActivatedRoute } from '@angular/router';
import { InformationsSpecifique } from './../../../entity/Opportunity/InformationsSpecifique';
import { InformationsComplmentaire } from './../../../entity/Opportunity/InformationsComplmentaire';
import { ApiOpportunitService } from './../../../services/ApiOpportunite/api-opportunite.service';
import { ApiStatusService } from './../../../services/ApiStatus/api-status.service';
import { StatusAutorise, StatutChild } from './../../../entity/Opportunity/StatusAutorise';

import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';

import { tap, catchError, map, startWith } from 'rxjs/operators';
import { AffaireDetails } from 'src/app/entity/Affaires/AffaireDetails';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import swal from 'sweetalert2';
import { Prospect } from '../../../entity/Prospect';
import { Entreprise } from '../../../entity/Opportunity/Entreprise';
import { ApiComminucationService } from 'src/app/services/ApiComminucation/api-comminucation.service';
import { HistoriqueComponent } from './historique/historique.component';
import { Subject } from 'rxjs-compat/Subject';
import { Observable } from 'rxjs-compat/Observable';
import { ListTypesAndParent } from 'src/app/entity/Opportunity/ListTypesAndParent';
import { HistoriquesAffaires } from 'src/app/entity/Affaires/HistoriquesAffaires';
import { InformationAvanceesComponent } from './information-avancees/information-avancees.component';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { AccessList } from 'src/app/entity/accessList';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StatusGarantieComponent } from './status-garantie/status-garantie.component';
import { EventEmiterService } from 'src/app/services/event.emmiter.service';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { element } from 'protractor';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Motif } from '../../parametres/gestion-status-affaire/fiche-status-affaire/fiche-status-affaire.component';
import { Process } from 'src/app/entity/Process';
import { DatePipe } from '@angular/common';
import { Subscription, forkJoin, of, throwError } from 'rxjs';
import { isEqual, differenceWith } from 'lodash';
import { HistoryService } from './history.service';
import { AssignModalComponent } from 'src/app/pages/affaires/affaire-details/modal/AffecterModal';
import { ValidationProvisoireComponent } from './validation-provisoire/validation-provisoire.component';
import { CommentaireServicesService } from 'src/app/services/ApiCommentaires/commentaire-services.service';
class GarantieAffaire {
  id_aff: string;
  garanties: any = [];
}
class Avenant {
  id_affaire: any;
  motif: any;
  commentaire: any;
}

export class BodyAction {
  id_affaire;
  id_statut;
  commentaire_action;
  id_motif;
  affected;
  commentaire;
  complement: { [key: string]: string };

  // commentaires;
}
export class MotifAffaire {
  id_affaire: any;
  id_motif: any;
  commentaire_action?: any;
}
class TypeErrorAffaire {
  info_banc: string = '';
  info_affaire: string = '';
  info_specifique: string = '';
  entreprise: string = '';
  garantie_affaire: string = '';
  objet_assure: string = '';
  infoDoc: string = '';
  quality_affaire: string = '';
}
@Component({
  selector: 'app-affaire-details',
  templateUrl: './affaire-details.component.html',
  styleUrls: ['./affaire-details.component.css'],
})
export class AffaireDetailsComponent implements OnInit, OnChanges {
  isProcessingAction: boolean = false;
  isFormLoaded: boolean = false;

  affaire_id: string = '';
  id_opportunite: string = '';
  prospect_id: string = '';
  contrat_id: string = '';
  private _listners = new Subject<any>();
  entreprise: Entreprise = null;
  societe: Entreprise = new Entreprise();
  entrepriseUpdated: boolean = false;
  contratUpdated: boolean = false;
  contratHistorique: boolean = false;
  contratInfoBancaire: boolean = false;
  contratuploadDocument: boolean = false;
  infoSpecifiqueUpdated: boolean = false;
  infoComplementaireUpdated: boolean = false;
  successChange: boolean = false;
  isListe: boolean = false;
  currency: string = environment.currentCurrency;
  formatCurrency: string = environment.formatCurrency;
  @ViewChild('assignModal') assignModal: TemplateRef<any>;
  @ViewChild(ValidationProvisoireComponent) validationProvisoireComponent: ValidationProvisoireComponent;
  hasValidationProvisoireForm: boolean = false;

  hasAddedRegionalDirDecisionMissingPieces: boolean = false;
  requiredStatusId = environment.complement_dossier_statut_id;
  private firstChangeActionCall: boolean = true;
  fromRoute: string;
  allowed_to_update_Demande_Modif: boolean = false;
  private modalRef: NgbModalRef;

  idSelected: any;
  ListIdProspect = [];
  item: number = 0;
  Actions = 'Changer le statut';
  Enregistrer = 'Mettre à jour';
  affaireDetailsUpdate: AffaireDetails = new AffaireDetails();
  // infoContrat: AffaireDetails;
  telNumber: any;
  prospect = new Prospect();
  status_autorise: StatusAutorise = new StatusAutorise();
  show_loading: boolean = false;
  show_loading_status: boolean = false;
  lastActionId: string = '';
  subStatusList: StatutChild[] = [];
  commentChangeAction: string = '';
  status: string = '';
  show_loadingAffaireComm: boolean;
  @ViewChild(HistoriqueComponent) childhisto: HistoriqueComponent;
  @ViewChild(InformationAvanceesComponent) informationAvanceesComponent: InformationAvanceesComponent;
  affaireData: string = '';
  informationsContrat: AffaireDetails = new AffaireDetails();
  infoComplementaire: InformationsComplmentaire;
  infoSpecifique: InformationsSpecifique = new InformationsSpecifique();
  mobileProspect: string = '';
  mailProspect: string = '';
  sum: number = 0;
  jsonObject = {};
  listParent: ListTypesAndParent[] = [];
  listTypes: ListTypesAndParent[] = [];
  type_envoie: any;
  statut_envoi: any;
  statut: any;
  formInformationSpecifique: any;
  ProspectExport: any;
  Historiquesexport: HistoriquesAffaires[] = [];
  oldBankInformation: BankInformation[] = [];
  status_id: any;
  allowed_to_update_affaire: boolean;
  demandeTypeInput : string = ""
  formDataUploadDocument: any = [];
  accessList: AccessList;
  ecriture_transaction_financiere_client: boolean;
  lecture_transaction_financiere_client: boolean;
  histAccess: boolean;
  histCommAccess: boolean;
  listeGarantieAffaire: any;
  CA: string;
  listGrantie: GarantieAffaire[] = [];
  exist: boolean = false;
  upadateGarantieAffaire: boolean = false;
  upadateobjetAssureAffaire: boolean = false;
  bodyGarantie: GarantieAffaire = new GarantieAffaire();
  listObjetAssurer: any[] = [];
  access_categories_aff: any[] = [];
  bien: boolean = false;
  person: boolean = false;
  errorProspect: boolean = false;
  info_bancaire: BankInformation[];
  listError: TypeErrorAffaire = new TypeErrorAffaire();
  appear = true;
  listMotif: any[] = [];
  idStatut: any;
  acces_avenant: boolean;
  alerts: any;
  buttons: any;
  navbarTxt: string = '';
  title: any;
  message: any;
  access_qualite: boolean;
  listOfProcess: Process[] = [];
  modifiedArray: any[] = [];
  quality_body: any;
  detect_change_quality: boolean = false;
  solde = 343.123345676789;
  transformDateDeff: string;
  pasMotif: any = {};
  noMotif: any;
  loaderMontant: boolean;
  idUserAffected: string;
  idAffectUser: any;
  idStatusWherAffected: string;
  idSelectedAffaire: string;
  userAffect: FormControl;
  listUserFilter: Observable<any[]>;
  liste_userAffect: any[] = [];
  isRoleDirector = localStorage.getItem('id_role') === '979d472a84804b9f647bc185a877a8b5' || null;
  decisionFinale: boolean = false;
  loader: boolean = false;
  public showInputStep: boolean = false;
  public userInput: string = '';
  restrictedStatusId = environment.complement_dossier_statut_id;
  validationProvStatusId = environment.en_cours_de_val;

  inputFields: string[] = [''];
  public userInputs: string[] = [''];
  commentaires: string = '';
  uploadedFile: File | null = null;
  uploadedFileComplement: File | null = null;

  complementFileNames: string[] = [];
  complementFiles: File[] = [];
  // hideStatus:string=""
  commentFileNames: string[] = [];
  commentText: string = '';
  public currentStep: 'status' | 'substatus' | 'commentFile' | 'input' | 'motifSelection' | 'confirmation' = 'status';
  public inputError: string = '';
  name_document: string;
  id_document: string = '';
  currentStatusId: any;
  selectedMotifId: string;
  showValidationBlock: boolean = true;
  loadingUpdateStatus: boolean = false;
  showHistory: boolean = true;
  isValidationProvisoireFormLoaded: boolean = false;
  formDataCommentaire: any;
  fromParam: string;
  language: any;
  validatedOrRefusedFianl:boolean =false
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private apiProspectService: ApiProspectsService,
    private apiAffairesService: ApiAffairesService,
    private apiOpportuniteServices: ApiOpportunitService,
    private apiProspectsService: ApiProspectsService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiStatusService: ApiStatusService,
    private apiCommunicationService: ApiComminucationService,
    private apiOpportunitService: ApiOpportunitService,
    private apiAdminBpmServices: ApiAdminBpmService,
    private apiDocumentsService: ApiDocumentsService,
    private notificationsService: NotificationsService,
    private HistoryService: HistoryService,
    private changeDetector: ChangeDetectorRef,
    private apiAuthentificationService: ApiAuthentificationService,
    private _eventEmiter: EventEmiterService,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private apiCommentraieService: CommentaireServicesService,
    private cdr: ChangeDetectorRef
  ) {
    this.listennumber().subscribe((m: any) => {
      this.sum = this.sum + m;
      if (this.sum > 0) {
        if (this.successChange === true) {
          // this.alertSuccess('Affaire modifiée avec succée');
          this.successChange = false;
        } else this.errorInfo();

        this.sum = 0;
        this.childhisto?.getHistorique();
        this.hideloader();
      }
    });

    this.apiDocumentsService.uploadDocument().subscribe((formDataDocuments: FormData) => {
      this.formDataUploadDocument.push(formDataDocuments);
      const lastItemsDict: { [key: string]: any } = {};
      this.modifiedArray = [];
      this.formDataUploadDocument.forEach((item) => {
        const keys = item.Formdata.keys();

        for (const key of keys) {
          if (key === 'file[]') {
            lastItemsDict[item.documentId] = item;
          }
        }
      });

      this.modifiedArray = Object.values(lastItemsDict);
    });

    this.userAffect = new FormControl();
  }

  removeInputField() {
    if (this.inputFields.length > 1) {
      this.inputFields.pop();
    }
  }

  isFormValid(): boolean {
    return this.inputFields.every((field) => field.trim() !== '');
  }

  onValidationProvisoireFormLoaded(event: boolean) {
    this.isValidationProvisoireFormLoaded = event;
  }

  updateValidationBlockVisibility(): void {
    this.showValidationBlock =
      this.status_id === this.validationProvStatusId ||
      
      this.status_id === environment.demande_refusee ||
      this.status_id === environment.validation ||
      this.status_id === environment.refus ||
      this.status_id === environment.valide_statut_id;
  }

  isDecisionFinalButtonDisabled(): boolean {
    let disableCadre = false
    const activeUser = localStorage.getItem('id_user');
    if (!this.informationsContrat || !this.informationsContrat.contrat) return true;
    if(this.informationsContrat.decision_final && environment.roleDirecteur == localStorage.getItem('id_role')){
      this.decisionFinale= true
    }else{
      this.decisionFinale= false
      if ( this.informationsContrat.contrat.affected_md5 !=activeUser && !this.informationsContrat.decision_final && this.informationsContrat.next_service != environment.servicePreDecision){
        disableCadre = true
      }else{
        disableCadre =false
      }

    }
    const idContratReal = this.informationsContrat.contrat.id_contrat_real;
    // const isSpecialContrat =
    //   idContratReal == environment.demande_modification_EPF_sans_impact_sur_le_BO ||
    //   idContratReal == environment.demande_modification_EPF_sans_validation;
   

    const isRefuseorValidStatus = [ environment.demande_refusee, environment.valide_statut_id
       ,environment.demande_valide_modif ,environment.demande_valide_modif_auto,environment.demande_refuse_modif,environment.demanderefuseeEpfExistant,
        environment.demandeValideEpfExistant, environment.demanderefuseeEpfExistant ,environment.demandeValideEpfExistant].includes(
      this.informationsContrat.contrat.etat_dossier_id 
    );

    
    let accessDirectorAffect  :any =true
    if (( this.informationsContrat.contrat.affected  != this.informationsContrat.contrat.director)  && (this.informationsContrat.contrat.director_md5==activeUser)){
      if (this.informationsContrat.next_service == environment.serviceFinalValidationFinal || this.informationsContrat.next_service== environment.serviceFinalValidationFinalExistant 
        || this.informationsContrat.next_service== environment.serviceFinaleValidationModificationAffectantBO || this.informationsContrat.next_service == environment.servicePreDecision
      ){
        accessDirectorAffect = true
      }else{
        accessDirectorAffect = false 
      }
     }



    if (this.hasValidationProvisoireForm) {
      return (
        !this.informationsContrat.contrat.affected ||
        isRefuseorValidStatus ||
        !this.isFormLoaded ||
        !accessDirectorAffect ||
        disableCadre ||
        !this.isValidationProvisoireFormLoaded
      );
    } else {
      return !this.informationsContrat.contrat.affected || !accessDirectorAffect || disableCadre || isRefuseorValidStatus || !this.isFormLoaded ;
    }
  }

  proceedToCommentFileStep(inputForm: NgForm): void {
    if (!inputForm.valid) {
      this.inputError = 'Veuillez remplir tous les champs obligatoires avant de continuer.';
      inputForm.form.markAllAsTouched();
      return;
    }

    if (this.userInputs.some((input) => input.trim() === '')) {
      this.inputError = 'Tous les champs doivent être remplis.';
    } else {
      this.currentStep = 'commentFile';
    }
  }

  async changeActionWithFile() {

    if (this.VisibleOnlyAdminActions && (!this.commentaires || this.commentaires.trim() === '')){
      this.CommentObligVisibleOnlyAdminActions()
    }else{
      this.isProcessingAction = true;
  
      if (!this.uploadedFile && !this.uploadedFileComplement && (!this.commentaires || this.commentaires.trim() === '')) {
        this.callChangeAction();
        this.isProcessingAction = false;
        this.commentaires = '';
        return;
      }
    
      const observables: Observable<any>[] = [];
    
      if (this.uploadedFileComplement) {
        const formDataComplement = new FormData();
        formDataComplement.append('file[]', this.uploadedFileComplement);
        formDataComplement.append('id_affaire', this.affaire_id);
        formDataComplement.append('id_type_doc', environment.visibleParAdmin);
        formDataComplement.append('name_doc', this.uploadedFileComplement.name);
        observables.push(
          this.uploadDocumentsAffaireComm(formDataComplement).pipe(
            map(response => ({ success: true, message: this.alerts.fileUploadedSuccessfully })),
            catchError(error => of({ error: true, message: this.alerts.failedUploadFile, details: error }))
          )
        );
      }
    
      if (this.uploadedFile) {
        const formDataFile = new FormData();
        formDataFile.append('file[]', this.uploadedFile);
        formDataFile.append('id_affaire', this.affaire_id);
        if (this.VisibleOnlyAdminActions){
          formDataFile.append('id_type_doc', environment.visibleParAdmin);
          
        }else{
          formDataFile.append('id_type_doc', environment.divers);
        }
        
        formDataFile.append('name_doc', this.uploadedFile.name);
        observables.push(
          this.uploadDocumentsAffaireComm(formDataFile).pipe(
            map(response => ({ success: true, message: this.alerts.fileUploadedSuccessfully })),
            catchError(error => of({ error: true, message: this.alerts.failedUploadFile, details: error }))
          )
        );
      }
    
      if (this.uploadedFile || this.commentaires) {
        const formDataCommentaire = new FormData();
        formDataCommentaire.append('entity', "A");
        formDataCommentaire.append('id_entity', this.affaire_id);
        formDataCommentaire.append('commentaire', this.commentaires);
        if (this.VisibleOnlyAdminActions){
          formDataCommentaire.append('visible_investisseur', "0");
        }else{
          formDataCommentaire.append('visible_investisseur', "1");
        }
        if (this.uploadedFile) {
          formDataCommentaire.append('docs', this.uploadedFile);
        }
    
        observables.push(
          this.apiCommentraieService.createCommentaire(formDataCommentaire).pipe(
            map(response => ({ success: true, message: this.alerts.commentAddedSuccessfully })),
            catchError(error => of({ error: true, message: this.alerts.failedComment, details: error }))
          )
        );
      }
    
      if (observables.length > 0) {
        forkJoin(observables).subscribe(
          (results) => {
            this.hideloader();
            let hasError = false;
            results.forEach((element: any, index: number) => {
              if (element.error) {
                this.notificationDialog(
                  NotificationType.error,
                  this.alerts.failedOperation,
                  element.message || this.alerts.problemservenuAffaire,
                  index + 1
                );
                hasError = true;
              } else {
                this.notificationDialog(
                  NotificationType.success,
                  this.alerts.Successoperation,
                  element.message,
                  index + 1
                );
              }
            });
            if (!hasError) {
              this.callChangeAction();
              this.reloadDocumentList();
              this.reloadComments = true;
            }
            this.isProcessingAction = false;
          },
          (error: any) => {
            this.isProcessingAction = false;
            // Handle error
          }
        );
      } else {
        this.callChangeAction();
      }
    }
    
  }


  

  
  reloadDocumentList(): void {
    this.apiDocumentsService.loadDocument$.next('refreshAffaire');
  }

  reloadComments = false;



  onFileChangeComplement(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files?.length) {
      this.uploadedFileComplement = input.files[0];
      this.name_document = this.uploadedFileComplement.name;
    }
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files?.length) {
      this.uploadedFile = input.files[0];
      this.name_document = this.uploadedFile.name;
    }
  }

  triggerFileInputComplement(): void {
    const fileInput = document.getElementById('fileUploadComplement') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  onComplementFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files?.length) {
      this.uploadedFile = input.files[0];
    }
  }

  getLoggedInUser(): any {
    const connectedUser = localStorage.getItem('id_user');
    if (connectedUser) {
      return connectedUser;
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
      this.accessList = value;
      this.ecriture_transaction_financiere_client = this.accessList.ecriture_transaction_financiere_client;
      this.lecture_transaction_financiere_client = this.accessList.lecture_transaction_financiere_client;
      this.histAccess = this.accessList.historique;
      this.histCommAccess = this.accessList.historique_communications;
      this.acces_avenant = this.accessList.acces_avenant;
      this.access_categories_aff = this.accessList.access_categories_aff;
      this.access_qualite = this.accessList.acces_qualite;
    });
  }


  getlistSpecif($event: any) {
    const jsonO = [];
    let test = false;
    const doc = $event;

    Object.keys(doc).forEach(function (value) {
      if (value === 'inf_prod') delete doc[value];
    });

    Object.assign($event, doc);
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'Infomation_Specific') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['Infomation_Specific'] = $event;

          // jsonO.push({ InfoSpecific: $event });
        }
      });
    });
    if (!test) jsonO['Infomation_Specific'] = $event;
    // Object.assign(this.jsonObject, jsonO);
  }

  getDataFormValue(dataForm: any): void {
    this.infoSpecifiqueUpdated = true;
    this.infoSpecifique.json_prod = JSON.parse(JSON.stringify(dataForm.data));
    this.infoSpecifique.model = dataForm.model;
    const regionalDirDecisionMissingPieces = dataForm.data['regional_dir_decision_missing_pieces'];
    if (
      regionalDirDecisionMissingPieces &&
      Array.isArray(regionalDirDecisionMissingPieces) &&
      regionalDirDecisionMissingPieces.length > 0
    ) {
      this.hasAddedRegionalDirDecisionMissingPieces = true;
    } else {
      this.hasAddedRegionalDirDecisionMissingPieces = false;
    }
  }

  getallowUpdateStatusComplememnt(event) {
    this.allowUpdateStatusComplememnt = event;
  }

  allowUpdateStatusComplememnt: boolean;

  getlistComp($event: any) {
    const jsonO = [];
    let test = false;
    const doc = $event;
    Object.keys(doc).forEach(function (value) {
      if (value === 'infcamp') delete doc[value];
    });

    Object.assign($event, doc);
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'Informations_Complementaire') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['Informations_Complementaire'] = $event;

          // jsonO.push({ InformationsComplmentaire: $event });
        }
      });
    });
    if (!test) jsonO['Informations_Complementaire'] = $event;

    // Object.assign(this.jsonObject, jsonO);
  }

  getListDocOpp($event: any) {
    if ($event.length > 0) {
      const jsonO = [];
      let test = false;
      const doc = $event;

      Object.assign($event, doc);
      // Object.assign(jsonO, this.jsonObject);
      jsonO.forEach((element, index) => {
        Object.keys(element).forEach(function (value) {
          if (value === 'List_Document_Opportunité') {
            jsonO.splice(index, 1);
            test = true;
            jsonO['List_Document_Opportunité'] = $event;

            // jsonO.push({ ListDocumentOpportunité: $event });
          }
        });
      });
      if (!test) jsonO['List_Document_Opportunité'] = $event;
      // Object.assign(this.jsonObject, jsonO);
    }
  }

  getlistContratenliason($event) {
    if ($event.length > 0) {
      // this.jsonObject['list_Affaire_En_Relation'] = $event;
    }
  }

  getListDoc($event: any) {
    if ($event.length > 0) {
      const jsonO = [];
      let test = false;
      const doc = $event;

      Object.assign($event, doc);
      // Object.assign(jsonO, this.jsonObject);
      jsonO.forEach((element, index) => {
        Object.keys(element).forEach(function (value) {
          if (value === 'List_Document') {
            jsonO.splice(index, 1);
            test = true;
            jsonO['List_Document'] = $event;

            // jsonO.push({ ListDocument: $event });
          }
        });
      });
      if (!test) jsonO['List_Document'] = $event;

      // Object.assign(this.jsonObject, jsonO);
    }
  }

  getlistecheancier($event) {
    if ($event.length > 0) {
      // this.jsonObject['list_echancier'] = $event;
    }
  }

  getlistpaimentfrais($event) {
    if ($event.length > 0) {
      const jsonO = [];
      let test = false;
      // Object.assign(jsonO, this.jsonObject);
      jsonO.forEach((element, index) => {
        Object.keys(element).forEach(function (value) {
          if (value === 'frais_de_paiement') {
            jsonO.splice(index, 1);
            test = true;
            jsonO['frais_de_paiement'] = $event;
            // jsonO.push({ paimentfrais: $event });
          }
        });
      });
      if (!test) jsonO['Statut_actuel'] = $event;
      // Object.assign(this.jsonObject, jsonO);
    }
  }

  /**
   *
   * @param bankInformation
   */

  getBankInformation(bankInformation: BankInformation[]): void {
    Object.assign(this.oldBankInformation, bankInformation);
    // this.successChange = true;
    if (this.oldBankInformation !== this.informationsContrat.info_bancaire) {
      this.info_bancaire = bankInformation;
      this.info_bancaire.forEach((element: BankInformation) => {
        if (element.iban) element.iban = element.iban.replace(/\s/g, '');
      });
    }
  }

  changedetectInfo(event) {
    this.contratInfoBancaire = event;
    this.listError.info_banc = '';
  }

  getlistcommentaireexport($event) {
    if ($event.length > 0) {
      const jsonO = [];
      let test = false;
      // Object.assign(jsonO, this.jsonObject);
      jsonO.forEach((element, index) => {
        Object.keys(element).forEach(function (value) {
          if (value === 'List_Commentaire') {
            jsonO.splice(index, 1);
            test = true;
            jsonO['List_Commentaire'] = $event;
            // jsonO.push({ ListCommentaire: $event });
          }
        });
      });
      if (!test) jsonO['List_Commentaire'] = $event;
      // Object.assign(this.jsonObject, jsonO);
    }
  }

  getlistticket($event) {
    if ($event.length > 0) {
      // this.jsonObject['List_Tickets_Affaires'] = $event;
    }
  }

  changeLang() {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.Enregistrer = this.buttons.enregistrer;
        this.noMotif = object.affaire.noMotif;
        this.language=object
        this.title = object.SigafConfig.changeStatusUnauth;
        this.message = object.SigafConfig.changeStatusUnauthMssg;
        if (this.fromRoute) {
          this.getNavbar()
        }else{
          this.updateMenuType()
        }
      });
    });
  }

  ngOnInit(): void {
    if (this.validationProvisoireComponent) {
      this.hasValidationProvisoireForm = true;
    } else {
      this.hasValidationProvisoireForm = false;
    }
    
    this.fromRoute = this.route.snapshot.queryParamMap.get('from');
    

    this.route.queryParams.subscribe((params) => {
      const from = params['from'];
      if (from === 'liste-EPF' || from === 'validee') {
        this.showHistory = false;
      } else {
        this.showHistory = true;
      }
    });

    this.userAffect = new FormControl();
    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.Enregistrer = this.buttons.enregistrer;
      this.noMotif = object.affaire.noMotif;
      
      this.language=object
      this.title = object.SigafConfig.changeStatusUnauth;
      this.message = object.SigafConfig.changeStatusUnauthMssg;
      // this.getNavbar(object)
    });
    this.changeLang();

    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
      this.accessList = value;
      this.ecriture_transaction_financiere_client = this.accessList.ecriture_transaction_financiere_client;
      this.lecture_transaction_financiere_client = this.accessList.lecture_transaction_financiere_client;
      this.histAccess = this.accessList.historique;
      this.histCommAccess = this.accessList.historique_communications;
      this.acces_avenant = this.accessList.acces_avenant;
      this.access_categories_aff = this.accessList.access_categories_aff;
      this.access_qualite = this.accessList.acces_qualite;
    });
    // this.showloader();
    this.affaireDeatilsApis();
    

    /* this.route.paramMap.subscribe((params) => {
      this.affaire_id = params.get('affaire_id');
      this.getInformationsContrat();
      this.apiAffairesService.getlistBpmAffaire(this.affaire_id).subscribe((data) => {
        this.listOfProcess = data;
        this.hideloader();

      });
    });
    this.apiAffairesService.listenLiaisonsContracts().subscribe((data: any) => {
      this.getInformationsContrat();
    });
    // this.getAffaireGarantie();
    this._eventEmiter.getListObject().subscribe((data) => {
      this.listObjetAssurer = data;
    }); */

    this.idUserAffected = this.informationsContrat.contrat.director;
    this.listUserFilter = this.userAffect.valueChanges.pipe(
      startWith(''),
      map((value: any) => (typeof value === 'string' ? value : value.nom)),
      map((value) => this._filterStatus(value || ''))
    );
  }

  getNavbar() {
    if (this.fromRoute) {
      
      
      switch (this.fromRoute) {
        case 'validee':
          this.demandeTypeInput='validee'
          this.navbarTxt = this.language.navbar.Affaires + ' ➜ ' + this.language.navbar.demandesvalidees + ' ➜ ' + this.language.navbar.Détails;

          break;
        case 'en-cours-traitement':
          this.demandeTypeInput='en-cours-traitement'
          this.navbarTxt = this.language.navbar.Affaires + ' ➜ ' + this.language.navbar.encourstraitement + ' ➜ ' + this.language.navbar.Détails;
          break;
        case 'nouvelles-demandes':
          this.demandeTypeInput='nouvelles-demandes'
          this.navbarTxt = this.language.navbar.Affaires + ' ➜ ' + this.language.navbar.nouvellesdemandes + ' ➜ ' + this.language.navbar.Détails;
          break;

        case 'demande-modification':
          this.demandeTypeInput='demande-modification'
          this.navbarTxt = this.language.navbar.demandemodification + ' ➜ ' + this.language.navbar.Détails;
          break;
        case 'Modif-nouvellesdemandes':
          this.demandeTypeInput='Modif-nouvellesdemandes'
          this.navbarTxt = this.language.navbar.demandemodification + ' ➜ ' + this.language.navbar.nouvellesdemandes+ ' ➜ ' + this.language.navbar.Détails;
          break;  
        case 'Modif-en-courstraitement':
          this.demandeTypeInput='Modif-en-courstraitement'
          this.navbarTxt = this.language.navbar.demandemodification + ' ➜ ' + this.language.navbar.encourstraitement+ ' ➜ ' + this.language.navbar.Détails;
          break;
        case 'epf-annexes':
          this.demandeTypeInput='epf-annexes'
          this.navbarTxt = this.language.navbar.epfannexes+ ' ➜ ' + this.language.navbar.Détails;
          break;

        case 'epf-existant':
          this.demandeTypeInput='epf-existant'
          this.navbarTxt = this.language.navbar.epfExistant + ' ➜ ' + this.language.navbar.Détails;
          break;
        case 'liste-EPF':
          this.demandeTypeInput='liste-EPF'
          this.navbarTxt = this.language.navbar.listeEPF + ' ➜ ' + this.language.navbar.Détails;
          break;
        case 'tasks-list':
          this.demandeTypeInput='tasks-list'
          this.navbarTxt = this.language.navbar.taskslist + ' ➜ ' + this.language.navbar.Détails;
          break;
        default:
          this.demandeTypeInput='affaires'
          this.navbarTxt = this.language.listing.Affaire + ' ➜ ' + this.language.navbar.Détails;
          break;
      }
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    } else {
      this.navbarTxt = this.language.listing.Affaire + ' ➜ ' + this.language.navbar.Détails;
      this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      this.demandeTypeInput='affaires'
     
    }
  }


  updateMenuType() {
    const contrat : any = this.informationsContrat.contrat.id_contrat_real ;
    const status =this.informationsContrat.contrat.etat_dossier_id
    
    if (contrat == environment.existant) {
     
        this.fromParam = 'epf-existant';
        
        this.navbarTxt = this.language.navbar.epfExistant + ' ➜ ' + this.language.navbar.Détails;
        
    } else if (contrat== environment.annexe && (status== environment.valide_statut_id ||
      status==environment.demande_valide_modif||
      status==environment.demande_valide_modif_auto) ) {
       
        this.navbarTxt = this.language.navbar.epfannexes+ ' ➜ ' + this.language.navbar.Détails;
        this.fromParam = 'epf-annexes';
    } else if ((contrat==environment.demande_modification_EPF_affectant_BO ||
      contrat==environment.demande_modification_EPF_sans_validation ||
      contrat==environment.demande_modification_EPF_sans_impact_sur_le_BO)  && status==environment.demande_modif_soumise) {
        this.demandeTypeInput='Modif-nouvellesdemandes'
        this.navbarTxt = this.language.navbar.demandemodification + ' ➜ ' + this.language.navbar.nouvellesdemandes+ ' ➜ ' + this.language.navbar.Détails;
    }
    else if ((contrat==environment.demande_modification_EPF_affectant_BO ||
      contrat==environment.demande_modification_EPF_sans_validation ||
      contrat==environment.demande_modification_EPF_sans_impact_sur_le_BO)  && (status==environment.demande_modif_demande_en_cours  ||
         status==environment.complement_dossier_demande_modif ||status==environment.demande_modif_complement_soumis )) {
        this.demandeTypeInput='Modif-en-courstraitement'
        this.navbarTxt = this.language.navbar.demandemodification + ' ➜ ' +  this.language.navbar.encourstraitement+ ' ➜ ' + this.language.navbar.Détails;
    } else if (contrat==environment.demande_modification_EPF_affectant_BO ||
               contrat==environment.demande_modification_EPF_sans_validation ||
               contrat==environment.demande_modification_EPF_sans_impact_sur_le_BO) {
        
        this.fromParam = 'demande-modification';
        this.navbarTxt = this.language.navbar.demandemodification + ' ➜ ' + this.language.navbar.Détails;
    }
   
    else if ((contrat==environment.ligne_prod_creation_epf || contrat==environment.annexe) && status==environment.soumise_statut_id) {
            
            this.fromParam = 'nouvelles-demandes';
            this.navbarTxt = this.language.navbar.Affaires + ' ➜ ' + this.language.navbar.nouvellesdemandes + ' ➜ ' + this.language.navbar.Détails;

    } else if ((contrat==environment.ligne_prod_creation_epf  || contrat==environment.annexe)  && 
               (status==environment.en_cours_traitement_statut_id ||
                status==environment.validation_provisoir_statut_id ||
                status==environment.en_cours_de_val ||
                status==environment.validation ||
                status==environment.complement_dossier_statut_id ||
                status==environment.complement_soumis_statut_id || status==environment.idComplémentnonvalide ||
                 status== environment.refuse || status==environment.statutValidePreDecison)) {
        
        this.fromParam = 'en-cours-traitement';
        this.navbarTxt = this.language.navbar.Affaires + ' ➜ ' + this.language.navbar.encourstraitement + ' ➜ ' + this.language.navbar.Détails;

    } else if ((contrat==environment.ligne_prod_creation_epf  || contrat==environment.annexe) && status==environment.valide_statut_id) {
        
        this.navbarTxt = this.language.navbar.Affaires + ' ➜ ' + this.language.navbar.demandesvalidees + ' ➜ ' + this.language.navbar.Détails;

        this.fromParam = 'validee';
    } else if ((status  == environment.valide_statut_id ||
               status  == environment.demande_valide_modif ||
               status  == environment.demande_valide_modif_auto || status==environment.demandeValideEpfExistant ) && (contrat==environment.ligne_prod_creation_epf || contrat==environment.existant ) ) {
       
        this.fromParam = 'liste-EPF';
        this.navbarTxt = this.language.navbar.listeEPF + ' ➜ ' + this.language.navbar.Détails;
    }
    //  else if (status == environment.affectation_statut_id ||
    //            status == environment.complement_soumis_statut_id ||
    //            status == environment.en_cours_traitement_statut_id ||
    //            status == environment.soumise_statut_id) {
       
    //     this.navbarTxt = this.language.navbar.taskslist + ' ➜ ' + this.language.navbar.Détails;
    //     this.fromParam = 'tasks-list';
    // }
    else if (contrat==environment.ligne_prod_creation_epf  || contrat==environment.annexe) {

        this.navbarTxt = this.language.listing.Affaire + ' ➜ ' + this.language.navbar.Détails;
        this.fromParam = 'affaires';
        }
    else {
            
        this.navbarTxt = this.language.listing.Affaire + ' ➜ ' + this.language.navbar.Détails;
        this.fromParam = 'affaires';
        }
    this.demandeTypeInput= this.fromParam
    this.sharedMenuObserverService.updateMenu(this.navbarTxt);
  }


  hasRelations(): boolean {
    const contrat = this.informationsContrat?.contrat;

    return (
      
      (contrat?.affaire_fils && contrat.affaire_fils.length > 0) ||
      (this.informationsContrat?.avenant && this.informationsContrat.avenant.length > 0) ||
      (contrat?.autre_affaires && contrat.autre_affaires.length > 0) ||
      (contrat?.laison_contrat && contrat.laison_contrat.length > 0)
    );
  }

  fetchListUserAffected() {
    this.apiAdminBpmServices.getListUserAffected(this.idUserAffected).subscribe(
      (response) => {
        this.liste_userAffect = response.list_cadres;
        this.getListUser(); // Initialize the autocomplete filter
        this.modalRef = this.modalService.open(this.assignModal);
      },
      (error) => {
        console.error('Error fetching list of users:', error);
      }
    );
  }

  openAssignModal() {
    if (!this.idUserAffected) {
      this.idUserAffected = this.informationsContrat?.contrat?.director;
    }
    if (this.idUserAffected) {
      const modalRef = this.modalService.open(AssignModalComponent, {
        backdrop: 'static',
        keyboard: false,
        beforeDismiss: () => {
          return !modalRef.componentInstance.isProcessingAction;
        },
      });
      modalRef.componentInstance.idUserAffected = this.idUserAffected;
      modalRef.componentInstance.affaire_id = this.affaire_id;
      modalRef.componentInstance.status_id = this.status_id;
      modalRef.componentInstance.informationsContrat = this.informationsContrat;
  
      modalRef.result.then(
        (result) => {
          if (result === 'Save click') {
            // if (this.informationsContrat?.contrat?.id_contrat_real == environment.existant) {
            //    this.alertSuccess(this.alerts.updatedActionSucess, true);
            // } else {
            //   this.lastActionId = this.status_autorise.listCategory[0].listactions[0].id;
            //   this.changeAction();
            //   this.affaireDeatilsApis();
            // }
            if ([environment.soumise_statut_id,environment.demande_modif_soumise,environment.soumiseEpfExistant].includes(this.informationsContrat?.contrat?.etat_dossier_id) ){

          
            this.apiStatusService
            .getStatutAutoriseAffaireGet(this.affaire_id)
            .subscribe((response) => {  
                this.status_autorise.listCategory = response.listactions;
                this.lastActionId = this.status_autorise.listCategory[0].listactions[0].id;
                this.changeAction();
                this.affaireDeatilsApis();
               
               
              });
            }else{
              this.alertSuccess(this.alerts.updatedActionSucess, true);
            }
            
          }
        },
        (reason) => {
          console.log('Modal dismissed:', reason);
        }
      );
    } else {
      console.error('idUserAffected is not set');
    }
    this.removeModalBackdrop();
  }
  
  
  affaireDeatilsApis(): void {
    this.route.paramMap.subscribe((params) => {
      this.affaire_id = params.get('affaire_id');
      this.showloader();
      forkJoin({
        listOfProcess: this.apiAffairesService.getlistBpmAffaire(this.affaire_id),
        informationsContrat: this.apiAffairesService.getAffaireInformationsContrat(this.affaire_id),
        listTypeAndParent: this.apiCommunicationService.GetListTypeAndParent(),
        detailsLibelleCourrier: this.apiCommunicationService.GetDetailsLibellePost(),
      }).subscribe(
        ({ listOfProcess, informationsContrat, listTypeAndParent, detailsLibelleCourrier }) => {
          // Handle listOfProcess
          this.listOfProcess = listOfProcess;

          // Handle informationsContrat
          this.informationsContrat = informationsContrat;
          if (this.fromRoute) {
            this.getNavbar()
          }else{
            this.updateMenuType()
          }
          
          this.bien = this.informationsContrat.contrat.bien === 1;
          this.person = this.informationsContrat.contrat.person === 1;
          this.status = this.informationsContrat.contrat.status;
          this.status_id = this.informationsContrat.contrat.etat_dossier_id;
          this.updateValidationBlockVisibility();
          this.getMotifByStatutActuel();
          
        
            const investor_page = this.informationsContrat.contrat.investor_page;
            const origin_contrat_id = this.informationsContrat.contrat.origin_contrat_id;
            // if (!investor_page && origin_contrat_id && origin_contrat_id != environment.annexe){
            //   this.hideStatus =environment.complement_dossier_demande_modif
            // }else
            // {
            //   this.hideStatus =""
            // }
            this.getStatusAutorise();
          const connectedUserId = this.getLoggedInUser();
        

          // if (
          //   this.informationsContrat.contrat.allowed_to_update &&
          //   (connectedUserId === this.informationsContrat.contrat.affected_md5 ||
          //     connectedUserId === this.informationsContrat.contrat.director_md5)
          // ) {
          //   this.allowed_to_update_affaire = true;
          // } else {
          //   this.allowed_to_update_affaire = false;
          // }
          this.allowed_to_update_affaire = this.informationsContrat.contrat.allowed_to_update;
          this.contrat_id = this.informationsContrat.contrat.id_contrat;
          this.id_opportunite =  this.informationsContrat.contrat.id_opp_md5;
          this.idUserAffected =  this.informationsContrat.contrat.director;
          this.getcontratexport(this.informationsContrat);
          this.validatedOrRefusedFianl =[ environment.demande_refusee, environment.valide_statut_id
            ,environment.demande_valide_modif ,environment.demande_valide_modif_auto,environment.demande_refuse_modif,environment.demanderefuseeEpfExistant,
             environment.demandeValideEpfExistant, environment.demanderefuseeEpfExistant ,environment.demandeValideEpfExistant].includes(
           this.informationsContrat.contrat.etat_dossier_id 
         )
         this.changeDetector.detectChanges();
          // Now that this.informationsContrat is set, we can safely call getMotifByStatutActuel()
          this.getMotifByStatutActuel();

          // Handle listTypeAndParent
          this.listParent = listTypeAndParent.parent;
          this.listTypes = listTypeAndParent.types;

          // Handle detailsLibelleCourrier
          this.statut = detailsLibelleCourrier.statut;
          this.statut_envoi = detailsLibelleCourrier.statut_envoi;
          this.type_envoie = detailsLibelleCourrier.type_envoie;

          // Hide loader
          this.hideloader();
          this.show_loadingAffaireComm = false;
          this.loaderMontant = false;
        },
        (error) => {
          this.openErrorAffaire(error?.error?.message);
          this.hideloader();
        }
      );
    });
  }

  getListTypeAndParent() {
    this.apiCommunicationService
      .GetListTypeAndParent()
      .pipe(
        tap(({ parent, types }) => {
          this.listParent = parent;
          this.listTypes = types;
        })
      )
      .subscribe();
  }

  getDetailsLibelleCourrier() {
    this.apiCommunicationService
      .GetDetailsLibellePost()
      .pipe(
        tap(({ statut, statut_envoi, type_envoie }) => {
          this.statut = statut;
          this.statut_envoi = statut_envoi;
          this.type_envoie = type_envoie;
        })
      )
      .subscribe();
  }

  getTelOpp() {
    this.apiOpportuniteServices.getProspectTelByOppId(this.id_opportunite).subscribe((data) => {
      this.telNumber = data;
    });
  }

  /*   getProspectTel() {
      this.apiProspectService.getProspectByOpprtuniteId(this.id_opportunite).subscribe((data) => {
        this.prospect = data;
       // this.societe = data;
      });
    }
   */
  getProspect(idopp: string) {
    this.apiProspectService.getProspectByOpprtuniteId(idopp).subscribe(
      (data) => {
        this.societe = data;
        this.prospect = data;
        this.hideloader();
      },
      (erro) => {
        this.hideloader();
      }
    );
  }

  // getInformationsContrat() {
  //   this.show_loadingAffaireComm = true;
  //   this.loaderMontant = true;
  //   this.apiAffairesService.getAffaireInformationsContrat(this.affaire_id).subscribe(
  //     (data: any) => {
  //       this.informationsContrat = data;
        
        

  //       /* if (this.informationsContrat.contrat.id_contrat_real) {
  //         this.allowed_to_update_Demande_Modif = true;
  //       } */
  //       if (this.informationsContrat.contrat.bien === 1) {
  //         this.bien = true;
  //       } else {
  //         this.bien = false;
  //       }
  //       if (this.informationsContrat.contrat.person === 1) {
  //         this.person = true;
  //       } else {
  //         this.person = false;
  //       }
  //       const connectedUserId = this.getLoggedInUser();
        
  //       // if (
  //       //   this.informationsContrat.contrat.allowed_to_update &&
  //       //   (connectedUserId === this.informationsContrat.contrat.affected_md5 ||
  //       //     connectedUserId === this.informationsContrat.contrat.director_md5)
  //       // ) {
  //       //   this.allowed_to_update_affaire = true;
  //       // } else {
  //       //   this.allowed_to_update_affaire = false;
  //       // }

  //       this.allowed_to_update_affaire = this.informationsContrat.contrat.allowed_to_update;
  //       this.status = this.informationsContrat.contrat.status;
  //       this.status_id = this.informationsContrat.contrat.etat_dossier_id;
  //       this.updateValidationBlockVisibility();

  //       this.contrat_id = data.contrat.id_contrat;
  //       this.id_opportunite = data.contrat.id_opp_md5;
  //       // this.prospect_id = data.contrat.id_prospect.toString();

  //       this.getListTypeAndParent();
  //       this.getDetailsLibelleCourrier();
  //       // this.getProspectTel();
  //       // this.getTelOpp();
  //       // this.getProspect(data.contrat.id_opp_md5);
  //       // this.jsonObject['Statut_actuel'] = this.informationsContrat.contrat.status;
  //       // this.jsonObject['status_id'] = this.informationsContrat.contrat.etat_dossier_id;
  //       // this.jsonObject['ligne_de_produit'] = this.informationsContrat?.contrat.type_contrat;
  //       this.getcontratexport(this.informationsContrat);
  //       this.show_loadingAffaireComm = false;
  //       this.loaderMontant = false;
  //       // this.showloader();
  //     },
  //     (error) => {
  //       // this.hideloader()
        
  //       this.openErrorAffaire(error?.error?.message);
  //     }
  //   );
  // }


  alertConfirmUpdateAffectToMe() {
      swal
        .fire({
          title: this.alerts.confirmAffectToself,
          icon: 'info',
          showConfirmButton: true,
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText:  this.buttons.annuler,
          confirmButtonText: this.buttons.confirmer,
          confirmButtonColor: '#28a8d2',
        })
        .then((result) => {
          if (result.isConfirmed) this.onAffecterUser();
                  });
    }

  openErrorAffaire(data) {
    this.changeDetector.detectChanges();

    swal
      .fire({
        title: this.alerts.error,
        text: data ? data : this.alerts.badHappened,
        icon: 'warning',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#d53a3a',
        customClass: {
          popup: 'border-radius-0',
        },
      })
      .then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          // this.router.navigate(['/affaires']);
          this.navigatetoList()
        }
      });
  }

  extractDate(inputDate) {
    if (inputDate) {
      const dateObject = new Date(inputDate);
      return this.datePipe.transform(dateObject, 'dd-MM-yyyy');
    }
  }

  existeDansListeSup: boolean = false;
  existeVisa: boolean = false;
  getStatusAutorise() {
    this.appearModel = true;
    this.appear = true;
    this.show_loading_status = true;
    this.apiStatusService
      .getStatutAutoriseAffaireGet(this.affaire_id)
      .pipe(
        tap(({ listactions }) => {
          this.status_autorise.listCategory = listactions;
          this.status_autorise.listCategory.forEach((element) => {
            this.show_loading_status = false;
            // if (this.hideStatus){
            //   element.listactions = element.listactions.filter(statut => statut.id !== this.hideStatus);
            // }
            element.listactions.forEach((statut) => {
              this.existeDansListeSup = this.access_categories_aff?.some((item) => item === element.id);
              if (this.existeDansListeSup === false && statut.sup === 1) {
                statut.access_sup = false;
              } else {
                statut.access_sup = true;
              }
              //  }
            });
          });
        })
      )
      .subscribe((data) => {
        this.show_loading_status = false;
      });
    this.removeModalBackdrop();
  }

  showModalUpdateStatut() {
    this.resetStatus();
    this.appear = false;
    this.appearModel = true;
    this.show_loading_status = true;

    this.apiStatusService
      .getStatutAutoriseAffaireGet(this.affaire_id)
      .pipe(
        tap(({ listactions }) => {
          this.status_autorise.listCategory = listactions;
          this.status_autorise.listCategory.forEach((element, index) => {
            // if (this.hideStatus){
            //   element.listactions = element.listactions.filter(statut => statut.id !== this.hideStatus);
            // }
            element.listactions.forEach((statut) => {
              this.show_loading_status = true;
              this.existeDansListeSup = this.access_categories_aff?.some((item) => item === element.id);
              if (this.existeDansListeSup === false && statut.sup === 1) {
                statut.access_sup = false;
              } else {
                statut.access_sup = true;
              }

              //  }
            });
            if (index + 1 === this.status_autorise?.listCategory?.length) {
              this.show_loading_status = false;
            }
          });
          if (this.status_autorise?.listCategory && this.status_autorise?.listCategory?.length > 0) {
            this.show_loading_status = false;
          }
        })
      )
      .subscribe(
        (data) => {},
        () => {
          this.show_loading_status = false;
        }
      );
  }

  appearS() {
    
    if ([environment.statutRefusePreDecison,environment.demanderefuseeEpfExistant,environment.demande_refusee,].includes(
    this.lastActionId
  )){
    this.step = false;}
    else{
      this.step = true;
    }
  }

  step: boolean = false;
  existeDansListeSupChild: boolean = false;



  alertConfirmUpdateFinalDecision(id) {
   
    swal
      .fire({
        title: this.alerts.confirmUpdatestatus,
        icon: 'info',
        showConfirmButton: true,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText:  this.buttons.annuler,
        confirmButtonText: this.buttons.confirmer,
        confirmButtonColor: '#28a8d2',
      })
      .then((result) => {
        if (result.isConfirmed){
          this.currentStep = null;
          this.step = true;
          this.show_loading = true;
          this.apiStatusService.getListActionsAffaireGet(id).subscribe(
            (data) => {
              if (data && data.class_etas_list) {
                this.subStatusList = data.class_etas_list;
      
                if (this.subStatusList.length > 0) {
                  // Process sub-statuses
                  this.subStatusList.forEach((element) => {
                    const isCategoryAccessible = this.access_categories_aff.includes(element.categorie);
                    element.access_sup = isCategoryAccessible || element.sup !== 1;
                  });
                  this.currentStep = 'status'; // Stay on sub-status step
                } else {
                  // No sub-statuses; proceed based on the selected status
                  // Fetch motifs for the selected status
                  this.getMotifByStatut(id);
                  // Do not set currentStep here; it will be set in getMotifByStatut
                }
              } else {
                console.warn('API returned no sub-statuses.');
                this.subStatusList = [];
                // Fetch motifs for the selected status
                this.getMotifByStatut(id);
                // Do not set currentStep here
              }
      
              this.show_loading = false;
            },
            (error) => {
              console.error('Error fetching sub-statuses:', error);
              this.show_loading = false;
              this.subStatusList = [];
            }
          );
        }else{
          this.showModalUpdateStatut();
        }
                });
  }

  commentTranslationKey='languages.affaire.redigezComment'
  
  VisibleOnlyAdminActions:boolean =false
  reserveStatus:boolean =false
  updateSubActionsList(id: string) {
     
    this.lastActionId = id;
    this.VisibleOnlyAdminActions= [environment.statusReserveCreation, environment.statusReserveepfExistant, environment.statusReserveModif ,environment.refuse,
      environment.validation,environment.refus,environment.avisFavorableEpExistant,environment.avisDefavorableEpExistant,environment.avisFavorabeDemandeModificationEpf,environment.avisDefavorabeDemandeModificationEpf].includes(this.lastActionId)
    this.reserveStatus=[environment.statusReserveCreation, environment.statusReserveepfExistant, environment.statusReserveModif 
      ].includes(this.lastActionId)


if ([environment.statutRefusePreDecison,environment.demanderefuseeEpfExistant,environment.demande_refusee,environment.demandeValideEpfExistant,environment.valide_statut_id,environment.demande_valide_modif,environment.demande_refuse_modif].includes(
  this.lastActionId
)){
  this.alertConfirmUpdateFinalDecision(id)
}else{


    // Optionally, set currentStep to null to prevent any unintended UI rendering
    this.currentStep = null;
    this.show_loading = true;
    this.apiStatusService.getListActionsAffaireGet(id).subscribe(
      (data) => {
        if (data && data.class_etas_list) {
          this.subStatusList = data.class_etas_list;

          if (this.subStatusList.length > 0) {
            // Process sub-statuses
            this.subStatusList.forEach((element) => {
              const isCategoryAccessible = this.access_categories_aff.includes(element.categorie);
              element.access_sup = isCategoryAccessible || element.sup !== 1;
            });
            this.currentStep = 'status'; // Stay on sub-status step
          } else {
            // No sub-statuses; proceed based on the selected status
            // Fetch motifs for the selected status
            this.getMotifByStatut(id);
            // Do not set currentStep here; it will be set in getMotifByStatut
          }
        } else {
          console.warn('API returned no sub-statuses.');
          this.subStatusList = [];
          // Fetch motifs for the selected status
          this.getMotifByStatut(id);
          // Do not set currentStep here
        }

        this.show_loading = false;
      },
      (error) => {
        console.error('Error fetching sub-statuses:', error);
        this.show_loading = false;
        this.subStatusList = [];
      }
    );

  }
  }

  /* proceedToConfirmation(inputForm: NgForm) {
  if (inputForm.valid) {
     this.currentStep = 'confirmation';
  } else {
     inputForm.form.markAllAsTouched();
  }
} */

  triggerFileInput(): void {
    const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  cancelFileUploadComment(): void {
    this.uploadedFile = null; // Clear the file reference
    const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
    fileInput.value = ''; // Clear the file input field
  }
  /**
   * config autocomplete workflow
   */

  AffectUser(user: any) {
    if (user && user.id_user) {
      this.idAffectUser = user.id_user;
      this.userAffect.setValue(user.id_user);
    } else {
      console.error('Invalid user object.');
    }
  }

  onAffecterUser() {
    const user = this.getLoggedInUser();
    if (user) {
      this.idAffectUser = user;
      if (this.affaire_id) {
        this.callAffectApi(this.affaire_id, this.idAffectUser);
      } else {
        console.error('No affaire ID provided.');
      }
    } else {
      console.error('No user is currently logged in.');
    }
  }

  isProcessingAffecting :boolean = false;
  callAffectApi(affaireId: string, userId: string) {
    const affectedId = localStorage.getItem('id_user');
    this.loader = true;
    this.isProcessingAffecting =true
    if (affectedId && affaireId) {
      this.apiAdminBpmServices.updateAffaireAffected(affectedId, affaireId).subscribe(
        (response) => {
          this.loader = false;
          
   
          this.apiStatusService
          .getStatutAutoriseAffaireGet(this.affaire_id)
          .subscribe((response) => {  
              this.status_autorise.listCategory = response.listactions;
              this.lastActionId = this.status_autorise.listCategory[0].listactions[0].id;
             
              this.changeAction();
            });
                

      //   this.lastActionId = environment.en_cours_traitement_statut_id; 
          
        },
        (error) => {
          this.loader = false;
          this.isProcessingAffecting =false
          console.error('Error affecting affair:', error);
        }
      );
    } else {
      console.error('Invalid affectedId or affaireId:', affectedId, affaireId);
    }
  }

  getListUser() { 
    this.listUserFilter = this.userAffect.valueChanges.pipe(
      map((value: any) => (typeof value === 'string' ? value : value.nom)),
      map((value) => this._filterStatus(value || ''))
    );
    this.changeDetector.detectChanges();
  }

  private _filterStatus(value: string): any[] {
    const filterValue = value.toLowerCase();
    if (!this.liste_userAffect || this.liste_userAffect.length === 0) {
      return [];
    }
    return this.liste_userAffect.filter(
      (user) =>
        (user.nom && user.nom.toLowerCase().includes(filterValue)) ||
        (user.prenom && user.prenom.toLowerCase().includes(filterValue))
    );
  }

  displayFnUser(user): string {
    return user && user.nom ? `${user.nom} ${user.prenom}` : '';
  }

  getlistMotif(id) {
    this.apiAdminBpmServices.getListMotifStatut(id).subscribe((response) => {
      this.liste_motif = response.Motifs;

      if (this.liste_motif.length === 0) {
        this.show_massage = true;
      } else {
        this.show_massage = false;
        this.pasMotif.active = 1;
        this.pasMotif.background = '#bc2525';
        this.pasMotif.color = '#FFF';
        this.pasMotif.libelle = this.noMotif;

        this.liste_motif.push(this.pasMotif);
        this.liste_userAffect = [];
      }

      this.loaderMotif = false;
    });
  }

  id_motif: any = '';
  changeAction() {
    
    if (!this.commentaires.trim() && !this.uploadedFile) {
      this.callChangeAction();
    } else if (this.uploadedFile) {
      const formData = new FormData();
      formData.append('file', this.uploadedFile);

      this.uploadDocumentsAffaire(formData).add(() => {
        this.callChangeAction();
        this.reloadDocumentList();
      });
    } else {
      this.callChangeAction();
    }
  }

  onFormLoaded(event: boolean) {
    this.isFormLoaded = event;
  }

  private callChangeAction() {
    this.show_loading = true;
    this.appearModel = false;
    this.isProcessingAffecting =true
    this.showloader()
    const bodyAction: BodyAction = new BodyAction();
    bodyAction.commentaire_action = this.commentaires;
    bodyAction.id_affaire = this.affaire_id;
    bodyAction.id_motif = this.id_motif || '';
    bodyAction.id_statut = this.lastActionId;
    this.currentStatusId = this.lastActionId;
    bodyAction.affected = null;

    // Préparer les données complémentaires
    const complementData: { [key: string]: string } = {};
    this.userInputs.forEach((input) => {
      if (input.trim()) {
        complementData[input] = '';
      }
    });
    bodyAction.complement = complementData;

    // this.showloader();
    this.loadingUpdateStatus = true;

    this.apiOpportunitService.updateaffaireAction(bodyAction).subscribe(
      () => {
        this.hideloader();
        this.loadingUpdateStatus = false;

        /* if (this.informationsContrat.contrat.laison_contrat.length > 0) {
          const x = this.informationsContrat.contrat.laison_contrat.length;
          // this.alertWarnningAction(x); Disable update relation affaire
        } else { */
        this.alertSuccess(this.alerts.updatedActionSucess);

        this.show_loading = false;
        this.step = false;
        this.show_massage = false;
        this.isProcessingAction = false;
      },
      (error) => {
        this.loadingUpdateStatus = false;
        this.hideloader();
        this.alertErrorAction(this.alerts.error, error.error.message);
        this.show_loading = false;
        this.isProcessingAction = false;
        this.removeModalBackdrop();
      }
    );
    this.removeModalBackdrop();
  }

  trackByIndex(index: number, item: any): any {
    return index;
  }

  addInputField(): void {
    this.userInputs.push('');
  }

  triggerCommentFileInput(): void {
    const fileInput = document.getElementById('commentFileUpload') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  triggerComplementFileInput(): void {
    const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  cancelFileUpload(): void {
    this.uploadedFileComplement = null;
    const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
    fileInput.value = ''; // Clear the file input value
  }


  show_massage: boolean = false;
  getMotif(id: string): void {
     this.id_motif = id;
    this.currentStep = 'commentFile';
    this.resetFieldsForCommentStep();
  }

  listennumber(): Observable<any> {
    return this._listners.asObservable();
  }

  pushnumber(filterBy: any) {
    this._listners.next(filterBy);
  }

  notificationDialog(type: number, title: string, message: string, time: number): void {
    const notificationMessage = new NotificationMessage();
    notificationMessage.type = type;
    notificationMessage.title = title;
    notificationMessage.message = message;
    notificationMessage.timeOut = 5000 + 2000 * time;
    this.notificationsService.sendMessage(notificationMessage);
  }

  getQualityData(event) {
    this.quality_body = event;
    this.detect_change_quality = true;
    this.listError.quality_affaire = '';
  }

  detectChangeQuality(event) {
    this.detect_change_quality = event;
  }

  async updateAffaire() {
    this.currentStatusId = this.informationsContrat?.contrat?.etat_dossier_id;

    if (this.hasAddedRegionalDirDecisionMissingPieces && this.currentStatusId !== this.requiredStatusId) {
      swal.fire({
        title: this.alerts.titleChangeStatus,
        text: this.alerts.messageChangeStatus,
        icon: 'warning',
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#d33',
      });
      return; // Do not proceed with save
    }

    let subscriptionsToFork: any[] = [];
    // To upload affaire documents
    if (this.modifiedArray.length > 0) {
      this.modifiedArray.forEach((element) => {
        subscriptionsToFork.push(
          this.apiDocumentsService.UploadDocuments(element.Formdata).pipe(
            catchError((error) => {
              return error; // Return an empty observable to allow zip to continue with the next observable
            })
          )
        );
      });
    }
    // To update bank informations
    /* if (this.contratInfoBancaire) {
          const hasEmptyFields = this.info_bancaire.some((item) => {
            return (
              item.titulaire_compte === '' ||
              item.iban === '' ||
              item.bic_swift === '' ||
              item.type_iban === '' ||
              item.nom_banque === ''
            );
          });
          if (hasEmptyFields) {
            this.listError.info_banc = this.alerts.fillObligInfoBanc;
          } else if (
            this.info_bancaire.length === 2 &&
            this.info_bancaire[0].type_iban === this.info_bancaire[1].type_iban
          ) {
            this.listError.info_banc = this.alerts.chooseDiffTypeIban;
          } else {
            subscriptionsToFork.push(
              this.apiAffairesService
                .UpdateInfoBancaire(this.affaire_id, this.info_bancaire)
                .pipe(catchError((error) => of({ error })))
            );
          }
        } */
    /* if (this.entrepriseUpdated) {
          // this.entreprise.json_object = JSON.parse(JSON.stringify(this.jsonObject));
          subscriptionsToFork.push(
            this.apiProspectsService
              .UpdateEntreprise(this.affaire_id, this.entreprise, this.id_opportunite)
              .pipe(catchError((error) => of({ error })))
          );
        } */

    /*  if (this.upadateGarantieAffaire) {
          subscriptionsToFork.push(
            this.apiAffairesService.updateAffaireGarantie(this.bodyGarantie).pipe(catchError((error) => of({ error })))
          );
        } */
    /* if (this.upadateobjetAssureAffaire) {
          if (this.listObjetAssurer.length > 0) {
            const nouvelleListe = this.listObjetAssurer.map((element) => {
              return {
                [element.nom]: { index: element.index, data: element.form.data },
              };
            });
            subscriptionsToFork.push(
              this.apiOpportunitService
                .update_objet_assure_by_entity(this.affaire_id, 'affaire', nouvelleListe)
                .pipe(catchError((error) => of({ error })))
            );
          } else {
            this.hideloader();
            this.alertError(this.alerts.chooseObjectAssure);

            this.show_loading = false;
          }
        } */
    /* if (this.contratUpdated) {
          // this.affaireDetailsUpdate = this.infoContrat;
          this.affaireDetailsUpdate.contrat.id_prospect = this.prospect_id;
          // this.showloader();
          // this.affaireDetailsUpdate.json_object = JSON.parse(JSON.stringify(this.jsonObject));
          subscriptionsToFork.push(
            this.apiAffairesService
              .UpdateAffaire(this.affaire_id, this.affaireDetailsUpdate)
              .pipe(catchError((error) => of({ error })))
          );
        } */
    /* if (this.detect_change_quality) {
          subscriptionsToFork.push(
            this.apiAffairesService
              .UpdateQualityAffaire(this.affaire_id, this.quality_body)
              .pipe(catchError((error) => of({ error })))
          );
        } */

    if (this.infoSpecifiqueUpdated) {
      subscriptionsToFork.push(
        this.apiOpportunitService
          .updateInformationsSpecifique(this.affaire_id, this.infoSpecifique)
          .pipe(catchError((error) => of({ error })))
      );
    }
    // this.showloader();
    if (this.infoSpecifiqueUpdated || this.modifiedArray.length > 0) {
      this.loaderMontant = true;
      forkJoin(subscriptionsToFork).subscribe(
        (results) => {
          this.hideloader();
          results.forEach((element: any, index: number) => {
            if (element?.error)
              this.notificationDialog(
                NotificationType.error,
                this.alerts.failedOperation,
                element?.error?.error?.message || this.alerts.problemservenuAffaire,
                index + 1
              );
            else {
              this.notificationDialog(
                NotificationType.success,
                this.alerts.Successoperation,
                element?.message,
                index + 1
              );
            }
          });
          this.HistoryService.sendFetchDataSignal(true);
          if (this.modifiedArray.length > 0) {
            this.apiDocumentsService.loadDocument$.next('refreshAffaire');
          }
          subscriptionsToFork = [];
          this.contratUpdated = false;
          this.upadateobjetAssureAffaire = false;
          this.upadateGarantieAffaire = false;
          // this.infoSpecifiqueUpdated = false;
          this.entrepriseUpdated = false;
          this.contratInfoBancaire = false;
          this.modifiedArray = [];
          this.formDataUploadDocument = [];
          this.detect_change_quality = false;
          this.loaderMontant = false;
        },
        (error) => {
          this.notificationDialog(
            NotificationType.error,
            this.alerts.failedOperation,
            error?.error?.message || this.alerts.problemservenuAffaire,
            2
          );
          this.loaderMontant = false;
          this.hideloader();
        }
      );
    } else {
      swal.fire({
        title: this.alerts.noModif,
        confirmButtonText: this.buttons.annuler,
        icon: 'info',
      });
    }

    /* v  */

    /* } else {
      this.hideloader();
      this.alertError(this.alerts.replirinfoAdherent);
      this.show_loading = false;
    } */
  }

  getError($event) {
    this.errorProspect = $event;
  }

  uploadDocumentsAffaire(formData: FormData): Subscription {
    this.successChange = false;
    return this.apiDocumentsService.UploadDocuments(formData).subscribe(
      (response: any) => {
        this.listError.infoDoc = '';
        this.apiAffairesService.pushdocumenthistorique('test');
        this.reloadDocumentList();
      },
      (error) => {
        this.successChange = false;
        this.listError.infoDoc = error.message;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue;
        notificationMessage.message = error.message;
        this.notificationsService.sendMessage(notificationMessage);
        this.isProcessingAction = false;
      }
    );
  }


  uploadDocumentsAffaireComm(formData: FormData): Observable<any> {
    this.successChange = false;
    return this.apiDocumentsService.UploadDocuments(formData).pipe(
      tap((response: any) => {
        this.listError.infoDoc = '';
        
      }),
      catchError((error) => {
        this.successChange = false;
        this.listError.infoDoc = error.message;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue;
        notificationMessage.message = error.message;
        this.notificationsService.sendMessage(notificationMessage);
        this.isProcessingAction = false;
        return throwError(error);
      })
    );
  }

  getcontratexport($event: AffaireDetails) {
    const jsonO = [];
    let test = false;
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'informations_Contrat') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['informations_Contrat'] = $event;

          // jsonO.push({ informationsContrat: $event });
        }
      });
    });
    if (!test) jsonO['informations_Contrat'] = $event;
    // Object.assign(this.jsonObject, jsonO);
  }

  onUpdateContrat($event: AffaireDetails) {
    this.affaireDetailsUpdate = $event;
    this.contratUpdated = true;
    this.listError.info_affaire = '';
    this.getcontratexport($event);
  }

  submitInfoCompl($event: any) {
    this.infoComplementaire = $event;
    this.infoComplementaireUpdated = true;
    const jsonO = [];
    let test = false;
    const doc = $event;
    Object.keys(doc).forEach(function (value) {
      if (value === 'infcamp') delete doc[value];
    });

    Object.assign($event, doc);
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'Informations_Complementaire') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['Informations_Complementaire'] = $event;

          // jsonO.push({ InformationsComplmentaire: $event });
        }
      });
    });
    if (!test) jsonO['Informations_Complementaire'] = $event;
    // Object.assign(this.jsonObject, jsonO);
  }

  submitInfoSpecifique($event: any) {
    this.infoSpecifique = $event;
    this.listError.info_specifique = '';
    this.infoSpecifiqueUpdated = true;
    const jsonO = [];
    let test = false;
    const doc = $event;
    Object.keys(doc).forEach(function (value) {
      if (value === 'inf_prod') delete doc[value];
    });

    Object.assign($event, doc);
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'Infomation_Specific') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['Infomation_Specific'] = $event;
          // jsonO.push({ InfoSpecific: $event });
        }
      });
    });
    if (!test) jsonO['Infomation_Specific'] = $event;
    // Object.assign(this.jsonObject, jsonO);
  }

  isObjectEqual(event, entreprise) {
    return isEqual(event, entreprise);
  }

  onUpdateSociete($event: Entreprise) {
    this.entrepriseUpdated = true;
    this.entreprise = $event;
    this.listError.entreprise = '';
  }

  alertSuccess(data: string, isExistant = false) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: 'Demande mise à jour',
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          this.motif = '';
          this.commentaire = '';
  
           if (isExistant) {
            this.ngOnInit();
          } else if (
            this.lastActionId != environment.en_cours_traitement_statut_id &&
            this.lastActionId != environment.en_cours_traitement_demande_modif
          ) {
            this.navigatetoList();
          } else {
            this.ngOnInit();
          }
        }
      });
  }
  

  alertSuccessAction(data) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: '',
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          if (this.informationsContrat.contrat.laison_contrat.length > 0) {
            const x = this.informationsContrat.contrat.laison_contrat.length;
            this.alertWarnningAction(x);
            this.subStatusList = [];
          } else {
            this.ngOnInit();
            this.motif = '';
            this.commentaire = '';
            this.Actualise();
          }
        }
      });
  }

  listErrorAction: any[] = [];
  listSuccessAction: any[] = [];
  listErrorAction2: any[] = [];
  listSuccessAction2: any[] = [];

  async alertWarnningAction(nbAffaire) {
    let message: string;
    if (nbAffaire === 1) {
      message = this.alerts.yavoir + this.alerts.uneAffaire + this.alerts.wantUpdateAffaire;
    } else {
      message = this.alerts.yavoir + nbAffaire + this.alerts.affaires + this.alerts.wantUpdateAffaire;
    }

    const result = await swal.fire({
      title: this.alerts.affaireLiee,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.buttons.Ignorer,
      confirmButtonText: this.alerts.Oui,
    });

    if (result.isConfirmed) {
      const bodyAction: BodyAction = new BodyAction();
      bodyAction.commentaire_action = this.commentChangeAction;
      this.id_motif ? (bodyAction.id_motif = this.id_motif) : (bodyAction.id_motif = '');
      bodyAction.id_statut = this.lastActionId;
      this.currentStatusId = this.lastActionId;
      for (const i of this.informationsContrat.contrat.laison_contrat) {
        try {
          bodyAction.id_affaire = i.id;
          const response = await this.apiOpportunitService.updateaffaireAction(bodyAction).toPromise();
          this.listSuccessAction.push(response.message);
        } catch (error) {
          this.listErrorAction.push(error.message);
        }
      }

      this.recapAlertAction(nbAffaire, this.listSuccessAction, this.listErrorAction);
    } else {
      this.Actualise();
    }
    this.step = false;
  }

  async alertWarnningMotif(nbAffaire, id_motif) {
    let message: string;
    if (nbAffaire === 1) {
      message = this.alerts.yavoir + this.alerts.uneAffaire + this.alerts.wantUpdateAffaire;
    } else {
      message = this.alerts.yavoir + nbAffaire + this.alerts.affaires + this.alerts.wantUpdateAffaire;
    }

    const result = await swal.fire({
      title: this.alerts.affaireLiee,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.buttons.Ignorer,
      confirmButtonText: this.alerts.Oui,
    });

    if (result.isConfirmed) {
      const BodyUpdatedMotif: MotifAffaire = new MotifAffaire();
      BodyUpdatedMotif.id_motif = id_motif;
      for (const i of this.informationsContrat.contrat.laison_contrat) {
        BodyUpdatedMotif.id_affaire = i.id;
        try {
          const response = await this.apiAffairesService.updateEtatDossierMotif(BodyUpdatedMotif).toPromise();
          this.listSuccessAction2.push(response.message);
        } catch (error) {
          this.listErrorAction2.push(error.message);
        }
      }
      this.recapAlertAction(nbAffaire, this.listSuccessAction2, this.listErrorAction2);
    } else {
      this.Actualise();
    }
    this.step = false;
  }

  recapAlertAction(nbAffaire, nbAffaireSecces, nbAffaireFailed) {
    const Message = `<h4> ${this.alerts.totalNumberAffaire} <b>
      ${nbAffaire}
      </b> </h4><p style='margin-top:12px;text-align: initial;margin-left: 44px;'> <img src='/assets/icons/check-circle.svg' >
      ${nbAffaireSecces.length}
       ${this.alerts.affairesUpdated}</p> <p style='text-align: initial;margin-left: 44px;'> <img src='/assets/icons/times-icon.svg'>
      ${nbAffaireFailed.length}
       ${this.alerts.affaireNotTreated}</p> `;
    swal
      .fire({
        html: Message,
        icon: 'info',
        cancelButtonColor: '#138eb6',
        focusConfirm: true,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: this.buttons.fermer,
      })
      .then((result) => {
        this.listErrorAction = [];
        this.listSuccessAction = [];
        this.Actualise();
        this.ngOnInit();
      });
  }

  alertWarning(title, invalidElements: any[]) {
    let listElementsAsString = '';
    invalidElements.forEach((element) => {
      if (typeof element.name === 'string')
        listElementsAsString =
          listElementsAsString +
          '<li style="list-style-type: none; font-size: 14px">' +
          element.name +
          ' : <u>' +
          element.value +
          '</u></li>';
    });
    swal.fire({
      title: title,
      icon: 'warning',
      html: '<ul class="p-0">' + listElementsAsString + '</ul>',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#e75e5e',
    });
  }

  alertWarning2(title, invalidElements: TypeErrorAffaire) {
    let listElementsAsString = '';
    /*     invalidElements.forEach((element) => {
          if (typeof element === 'string')
            listElementsAsString =
              listElementsAsString +
              '<li style="list-style-type: none; font-size: 14px">' +
              ' <u>' + element + '</u></li>';
        }); */
    listElementsAsString =
      '<li style="list-style-type: none; font-size: 14px">' +
      ' <u>' +
      invalidElements.info_affaire +
      '</u>' +
      ' <u>' +
      invalidElements.info_banc +
      '</u>' +
      ' <u>' +
      invalidElements.info_specifique +
      '</u>' +
      ' <u>' +
      invalidElements.objet_assure +
      '</u>' +
      ' <u>' +
      invalidElements.entreprise +
      '</u>' +
      ' <u>' +
      invalidElements.garantie_affaire +
      '</u>' +
      ' <u>' +
      invalidElements.infoDoc +
      '</u>' +
      '</li>';
    swal
      .fire({
        title: title,
        icon: 'warning',
        html: '<ul class="p-0">' + listElementsAsString + '</ul>',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#e75e5e',
      })
      .then((result) => {
        // this.listError = [];
      });
  }

  alertError(data) {
    swal.fire({
      title: this.alerts.error,
      text: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      allowOutsideClick: false,
      confirmButtonColor: '#d53a3a',
    });
  }

  CommentObligVisibleOnlyAdminActions() {
    swal.fire({
      text: this.alerts.commentRequired,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      allowOutsideClick: false,
      confirmButtonColor: '#d53a3a',
    });
  }

  alertErrorAction(data, err) {
    swal
      .fire({
        title: this.alerts.error,
        text: err,
        icon: 'error',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        allowOutsideClick: false,
        confirmButtonColor: '#d53a3a',
      })
      .then(() => {
        //  this.subStatusList=[];
        this.step = false;
        this.removeModalBackdrop();
      });
  }

  private removeModalBackdrop() {
    const backdropElements = document.querySelectorAll('.modal-backdrop.fade.show');
    backdropElements.forEach((element) => {
      element.classList.remove('modal-backdrop', 'fade', 'show');
    });
    document.body.style.overflow = 'auto';
  }

  errorInfo() {
    swal.fire({
      title: this.alerts.noModif,
      confirmButtonText: this.buttons.annuler,
      icon: 'info',
    });
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  scroll(el: string) {
    const element = document.getElementById(el);
    const offset = 120;
    const bodyRect = document.body.getBoundingClientRect().top;
    if (element != null) {
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  Actualise() {
    this.removeModalBackdrop();
    this.ngOnInit();
  }

  getProspectDoublon(event: any) {
    if (event.target.checked === true) {
      this.idSelected = event.target.value;
      this.ListIdProspect[this.item] = this.idSelected;
      this.item++;
    } else {
      this.idSelected = event.target.value;
      this.deleteItem(this.idSelected);
      this.item--;
    }
  }

  deleteItem(element) {
    const index: number = this.ListIdProspect.indexOf(element);
    if (index !== -1) {
      this.ListIdProspect.splice(index, 1);
    }
  }

  setFussionProspectDoublon() {
    this.apiProspectsService.fussionProspectDoublon(this.ListIdProspect).subscribe(
      () => {
        this.ngOnInit();
        this.alertSuccess(this.alerts.ProspectFussionner);
      },
      () => {
        this.alertError(this.alerts.error);
      }
    );
  }

  ngOnDestroy(): void {
    this.apiOpportunitService.formdataaffaircomp = null;
    this.apiOpportunitService.formdataaffairspecif = null;
    if (!this.isListe) {
      localStorage.removeItem('filtreAffaire');
      localStorage.removeItem('page');
      localStorage.removeItem('sort_field');
      localStorage.removeItem('sort_type');
      localStorage.removeItem('sSearch');
    }
  }

  /**
   * Permets de retourner à la liste
   */

  navigatetoList(){
    const savedFilter = localStorage.getItem('savedFilter');
    const savedCollection = localStorage.getItem('savedCollection');
    const queryParams = {
      filter: savedFilter,
      collection: savedCollection,
    };
    if (this.fromRoute) {
      switch (this.fromRoute) {
        case 'validee':
          this.router.navigate(['/demandes-validees'], { queryParams: queryParams });
          break;
        case 'en-cours-traitement':
          this.router.navigate(['/en-cours-traitement'], { queryParams: queryParams });
          break;
        case 'nouvelles-demandes':
          this.router.navigate(['/nouvelles-demandes'], { queryParams: queryParams });
          break;

        case 'demande-modification':
          this.router.navigate(['/demande-modification'], { queryParams: queryParams });
          break;
        case 'Modif-nouvellesdemandes':
          this.router.navigate(['/Modif-nouvellesdemandes'], { queryParams: queryParams });
          break;  
        case 'Modif-en-courstraitement':
          this.router.navigate(['/Modif-en-courstraitement'], { queryParams: queryParams });
          break; 
        case 'epf-annexes':
          this.router.navigate(['/epf-annexes'], { queryParams: queryParams });
          break;

        case 'epf-existant':
          this.router.navigate(['/epf-existant'], { queryParams: queryParams });
          break;
        case 'liste-EPF':
          this.router.navigate(['/liste-EPF'], { queryParams: queryParams });
          break;
        case 'tasks-list':
          this.router.navigate(['/tasks-list'], { queryParams: queryParams });
          break;
        default:
          this.router.navigate(['/affaires'], { queryParams: queryParams });
          break;
      }
    } else {
      this.router.navigate(['/affaires'], { queryParams: queryParams });
    }
  }

  returnToList(): void {
    this.isListe = true;
    this.navigatetoList()
    
  }

  getFormInfoSpecifique(form: any): void {
    this.formInformationSpecifique = form;
  }

  validateFormInformationSpecifique(): boolean {
    if (this.formInformationSpecifique !== undefined) {
      const invalidElements = [];
      for (const [key, value] of Object.entries(this.formInformationSpecifique.controls)) {
        const element: any = value;
        if (element.status === 'INVALID') {
          invalidElements.push({ name: key, value: element.value });
        }
      }
      if (this.formInformationSpecifique.status === 'INVALID') {
        this.alertWarning(this.alerts.infoCompLigneProduitInvalid, invalidElements);
        this.apiAdminBpmServices.sharedFormIsValid = false;
        this.infoSpecifique = null;
        this.infoSpecifiqueUpdated = false;
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  getAffaireGarantie() {
    this.apiAffairesService.getAffaireGarantieByID(this.affaire_id).subscribe((response) => {
      this.listeGarantieAffaire = response;
    });
  }

  addAffaireGarantie(body: any) {
    this.apiAffairesService.updateAffaireGarantie(body).subscribe(
      (response) => {
        this.alertSuccess(this.alerts.affaireGarantieUpdated);
      },
      (error) => {
        this.alertError(error.error.message);
      }
    );
  }

  onUpdateGaranties($event: any) {
    this.bodyGarantie = $event;
    this.upadateGarantieAffaire = true;
    this.listError.garantie_affaire = '';
  }

  updateobjetAssure($event: boolean) {
    this.upadateobjetAssureAffaire = true;
    this.listError.objet_assure = '';
  }

  statutappear() {
    this.appear = false;
  }

  removeSpecificInputField(index: number): void {
    if (this.userInputs.length > 1) {
      this.userInputs.splice(index, 1);
    }
  }

  appearModel: boolean = false;
  resetStatus() {
    this.inputFields = [''];
    this.currentStep = 'status';
    this.step = false;
    this.appear = false;
    this.appearModel = false;
    this.show_massage = false;
    this.liste_motif = [];
    this.liste_userAffect = [];
    this.userInputs = [''];
    this.uploadedFile = null;
    this.uploadedFileComplement = null;
    this.name_document = '';
    this.commentText = '';
    this.lastActionId = '';
    this.subStatusList = [];
    this.inputError = '';
    this.commentaires = '';
  }

  retourSubmit() {
    this.appear = true;
  }

  motif: any;
  commentaire: any;
  MotifControl = new FormControl('', Validators.required);
  creerAvenant() {
    const bodyAvenant: Avenant = new Avenant();
    bodyAvenant.id_affaire = this.affaire_id;
    this.MotifControl.value ? (bodyAvenant.motif = this.MotifControl.value) : (bodyAvenant.motif = '');
    this.commentaire ? (bodyAvenant.commentaire = this.commentaire) : (bodyAvenant.commentaire = '');
    // bodyAvenant.motif= this.MotifControl.value;
    // bodyAvenant.commentaire= this.commentaire;
    if (!this.MotifControl.value) {
      swal.fire({
        title: this.alerts.error,
        text: this.alerts.motifOblig,
        icon: 'error',
        allowOutsideClick: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#d53a3a',
      });
      //  this.alertError("Motif obligatoire");
    } else {
      this.apiAffairesService.CreerAvenant(bodyAvenant).subscribe(
        (response) => {
          window.open(
            this.informationsContrat.contrat.gamme.url_tarification +
              '?token=' +
              response.token +
              '&id_prospect=' +
              response.id_prospect +
              '&id_affaire=' +
              response.id_affaire +
              '&id_opp=' +
              this.informationsContrat.contrat.id_opp,
            '_blank'
          );
          this.MotifControl.setValue('');
          this.commentaire = '';
          this.Actualise();
        },
        () => {
          this.alertError(this.alerts.error);
          this.MotifControl.setValue('');
          this.commentaire = '';
        }
      );
    }
  }

  getListeMotifAvenant() {
    this.apiAffairesService.getlistMotifAvenant().subscribe((response) => {
      this.listMotif = response.motif;
    });
  }

  showModal = false;
  openModalOrPopup() {
    this.commentaire = '';
    this.uploadedFile = null;
    this.uploadedFileComplement = null;
    this.name_document = '';
    this.commentText = '';

    if (this.informationsContrat.contrat.gamme?.url_tarification) {
      this.showModal = true;
      this.getListeMotifAvenant();
    } else {
      this.showModal = false;
      const nomGamme = this.informationsContrat.contrat.gamme?.nom;
      const message = `${this.alerts.verifyLienTarif} "<b>${nomGamme}</b>"`;

      swal.fire({
        html: message,
        icon: 'warning',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#d53a3a',
        customClass: {
          popup: 'border-radius-0',
        },
      });

      // this.alertError()
    }
  }

  NaviagateToOppoDetials(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/opportunities/details/' + id + '/prospect']));
    window.open(url, '_blank');
    //  this.router.navigate(['/opportunities/details/' + id + '/prospect']);
  }

  liste_motif: Motif[] = [];
  loaderMotif: boolean = true;

  getMotifByStatut(id: string): void {
    this.apiAdminBpmServices.getListMotifStatut(id).subscribe(
      (response) => {
 
        // Check if response is an array or an object with Motifs property
        if (Array.isArray(response)) {
          this.liste_motif = response;
        } else if (response && response.Motifs) {
          this.liste_motif = response.Motifs;
        } else {
          this.liste_motif = [];
        }

 
        if (this.liste_motif.length === 0 ) {
          this.show_massage = true;
          // No motifs, decide next step based on status
            if (this.lastActionId != environment.valide_statut_id && this.lastActionId != environment.demandeValideEpfExistant && this.lastActionId != environment.demande_valide_modif){
              if (String(this.lastActionId) === String(this.requiredStatusId) || String(this.lastActionId) === String(environment.complement_dossier_demande_modif) || String(this.lastActionId) === String(environment.complement_dossier_epf_existant) ) {
                this.currentStep = 'input';
              } else {
                this.currentStep = 'commentFile';
              }
            }
            else{
              this.changeActionWithFile()
            }
          
        } else {
          this.show_massage = false;
          this.pasMotif.active = 1;
          this.pasMotif.background = '#bc2525';
          this.pasMotif.color = '#FFF';
          this.pasMotif.libelle = this.noMotif;
          this.liste_motif.push(this.pasMotif);
          this.liste_userAffect = [];
          this.currentStep = 'motifSelection';
        }

        this.loaderMotif = false;
      },
      (error) => {
         console.error('Error fetching motifs:', error);
        this.liste_motif = [];
        this.loaderMotif = false;
        if ( this.lastActionId !=environment.valide_statut_id  && this.lastActionId != environment.demandeValideEpfExistant && this.lastActionId != environment.demande_valide_modif){
          if (String(this.lastActionId) === String(this.requiredStatusId) || String(this.lastActionId) === String(environment.complement_dossier_demande_modif) ) {
            this.currentStep = 'input';
          } else {
            this.currentStep = 'commentFile';
          }
        }else{
          this.changeActionWithFile()
        }
        
      }
    );
  }

  proceedToCommentFileStepAfterMotif(selectedMotifId: string): void {
     this.id_motif = selectedMotifId;
    this.currentStep = 'commentFile';
    this.resetFieldsForCommentStep();
  }

  private resetFieldsForCommentStep(): void {
    this.commentaires = '';
    this.uploadedFile = null;
  }

  list_motif_statut_actuel: Motif[] = [];
  loadermotifActuel: boolean = false;
  showModalMotif: boolean = true;
  getMotifByStatutActuel() {
    this.loadermotifActuel = true;
    this.showModalMotif = true;
    this.apiAdminBpmServices.getListMotifStatut(this.informationsContrat.contrat.etat_dossier_id).subscribe(
      (response) => {
        this.list_motif_statut_actuel = response.Motifs.filter(
          (element) => element.id !== this.informationsContrat.contrat.motif.id
        );

        this.loadermotifActuel = false;
      },
      () => {
        this.loadermotifActuel = false;
      }
    );
  }

  updateMotif(id_motif) {
    const BodyUpdatedMotif: MotifAffaire = new MotifAffaire();
    BodyUpdatedMotif.id_affaire = this.affaire_id;
    BodyUpdatedMotif.id_motif = id_motif;
    this.showModalMotif = false;
    this.apiAffairesService.updateEtatDossierMotif(BodyUpdatedMotif).subscribe(
      (response) => {
        if (this.informationsContrat.contrat.laison_contrat.length > 0) {
          const x = this.informationsContrat.contrat.laison_contrat.length;
          this.alertWarnningMotif(x, id_motif);
          this.showModalMotif = false;
        } else {
          this.alertSuccess(this.alerts.updatedActionSucess);
          this.showModalMotif = false;
        }
        // this.alertSuccess('Action mise a jour avec succès')
        // let x = this.informationsContrat.contrat.laison_contrat.length

        // this.alertWarnningMotif(x ,id_motif);
        this.showModalMotif = false;
      },
      () => {
        this.alertErrorAction(this.alerts.error, this.alerts.badHappened);
        this.showModalMotif = false;
      }
    );
  }

  resetMotif() {
    this.list_motif_statut_actuel = [];
    this.showModalMotif = false;
  }

  expandFileName(name: string): string {
    if (name.length < 20) {
      return name;
    } else {
      return name.substring(0, 17) + '...';
    }
  }
}
