<div class="row">
    <div class=" position-fixed " style="z-index: 5;">
        <app-filter-affaire-global *ngIf="showFiltreDetaille" [collapseFromParent]="collapseFromParent"
            (collapseCloseToparent)="getCollapseValue($event)" (onSubmitFilter)="getDataValue($event)"
            (onPushFilters)="EmitFilterAffaire($event)" [filterSsearch]="filterSsearch">
        </app-filter-affaire-global>
    </div>
</div>
<div class="row " #myDiv>
  
    <!-- Datatables -->
    <div class="col-lg-12">
         
        <div class="card mb-4 border-0" style="border-radius: 13px;">

            <div class="row d-flex align-items-center w-100">
                <div class="col-2">
                <div class="card-info-1 w-100 mt-2"  >
                    <p class="p-info-1">{{TotaleDemande}}</p>
                    <p class="p-info-2"> {{'languages.SigafConfig.label_total' | translate}}</p>
                  </div>
                </div>

                <div class="col-8 blocDate mt-2">
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-4 p-0">
                            <mat-form-field appearance="outline" class="w-100" style="height:46px" >
                                <mat-label> {{'languages.opportunite.natureFormation' | translate}}</mat-label>
                                <mat-select name="envelope" [(ngModel)]="natureDemande"  placeholder="{{'languages.opportunite.natureFormation' | translate}}" >
                                    <mat-option value="">
                                        
                                    </mat-option>
                                    <mat-option value="أساسي">
                                        Initiale - أساسي
                                    </mat-option>
                                    <mat-option value="مستمر">
                                        Continue - مستمر
                                    </mat-option>
                                    <mat-option value="أساسي ومستمر">
                                        Initiale et continue - أساسي ومستمر
                                    </mat-option>
                                </mat-select>
                                <button mat-icon-button matSuffix *ngIf="natureDemande"(click)="natureDemande = ''">
                                    <mat-icon>clear</mat-icon>
                                  </button>
                            </mat-form-field>
                        </div>
                        
                        <div class="col-1">
                            <img src="assets/icons/calendarListing.svg" class="mr-3" alt="">
                        </div>
                        <!-- <div class="col-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label> {{'languages.listing.typeDate' | translate}}</mat-label>
                                <mat-select [(value)]="selected" (change)="quickFilterChange()">
                                    <mat-option value="Creation">
                                       {{'languages.opportunite.label_creationdate' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
                       
                        <div class="col-5">

                            <mat-form-field appearance="standard" class="w-100 ">
                                {{'languages.opportunite.label_creationdate' | translate}}
                                <mat-date-range-input [rangePicker]="Effet">
                                    <input [(ngModel)]="date_debut" readonly matStartDate placeholder="Date début"
                                        (click)="openDateeffet()" />
                                    <input [(ngModel)]="date_fin" readonly matEndDate placeholder="date fin"
                                        (click)="openDateeffet()" />

                                </mat-date-range-input>
                                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="Effet"> <mat-icon
                                        matDatepickerToggleIcon>
                                        <img src="assets/icons/calendar.svg">
                                    </mat-icon></mat-datepicker-toggle>
                                <img class="remouveDate" matSuffix (click)="clearDateeffet()"
                                    src="assets/icons/delete-left.svg">
                                <mat-date-range-picker [calendarHeaderComponent]="rangeDateEffet"
                                    #Effet></mat-date-range-picker>


                            </mat-form-field>

                        </div>
                        <!-- <div class="col-4">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{'languages.listing.label_debutdate' | translate}}</mat-label>
                                <input matInput [matDatepicker]="pickerDebut" id="date_debut" name="date_debut" [(ngModel)]="date_debut" [value]="date_debut">
                                <mat-datepicker-toggle matSuffix [for]="pickerDebut"></mat-datepicker-toggle>
                                <mat-datepicker #pickerDebut></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{'languages.listing.label_enddate' | translate}} </mat-label>
                                <input matInput [matDatepicker]="pickerFin" id="date_fin" name="date_fin" [(ngModel)]="date_fin" [value]="date_fin">
                                <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                                <mat-datepicker #pickerFin></mat-datepicker>
                            </mat-form-field>
                        </div> -->
                        <div class="col-2 w-100 pl-0 d-flex justify-content-center">
                            <button type="button" class="filtrer" (click)="getDataValue('')">
                             <img src="assets/icons/filtre.svg" class="mr-3" alt="">
                             {{'languages.buttons.filtrer' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-3 mt-2 p-2 d-flex justify-content-center">
                    <button class="filtreFavoris">
                        <img class="mr-3" src="assets/icons/filtreFavoris.svg">
                        {{'languages.buttons.rechercheFavories' | translate}}
                    </button>
                </div> -->
                <div class="col-2 p-2  text-right">
                    <input class="search w-100" name="" [(ngModel)]="sSearch"  (input)="onInputChange($event.target.value)" placeholder="{{'languages.miniListing.search' | translate}}">
                    <i class=" fa-solid fa-search icon-search"></i>
                </div>

            </div>

            <div class="row mt-3" >

                <button type="button" *ngIf=" (isNouvelles || isTasks)" class="btn-dt-actions-groupee ml-4" (click)="updateAffaire()" [disabled]="!actionButton"
                 >
                 {{'languages.groupedactions.affectationGroupee' | translate}}
                  </button>
                  <button  type="button" class="btn-dt-exporter" (click)="exportDataToCsv()" [disabled]="affairesList.aaData?.length==0"   [ngStyle]="{'opacity': affairesList.aaData?.length==0 ? '0.5' : '1'}">
                    <i class="fa-solid fa-file-excel fa-lg"></i>
                           {{'languages.buttons.exporterCSV' | translate}}
                </button>
                </div>


            <div *ngIf="!showListingEPFcree" class="table-responsive p-3">
                <table class="table align-items-center table-flush" id="dataTable1">
                    <thead class="head-table">
                        <tr>
                            <th *ngIf="isNouvelles || isTasks" >
                                <input type="checkbox" *ngIf="isNouvelles" [(ngModel)]="masterSelected" name="list_name" value="m1"
                                    (change)="checkUncheckAll()" />
                            </th>
                            <th >{{'languages.listing.actions' | translate}}
                            </th>


                            <th>{{'languages.SigafConfig.RagistrNum' | translate}}</th>
                            <th>{{'languages.listing.Type' | translate}}</th>
                            <th>{{'languages.SigafConfig.Denomination' | translate}}</th>
                            <th>{{'languages.affaire.directeur' | translate}}</th>
                            
                            <th>{{'languages.user.goverment' | translate}}</th>
                            
                            <th>{{'languages.SigafConfig.delegation' | translate}}</th>
                            <th>{{'languages.SigafConfig.matriculeFiscale' | translate}}</th>
                            <th>{{'languages.prospect.phone' | translate}}</th>
                            <th>{{'languages.opportunite.natureFormation' | translate}}</th>
                            <th>{{'languages.affaire.dateDeffet' | translate}}</th>
                            <th  >
                                {{'languages.opportunite.statutInterne' | translate}}
                           
                            </th>


                            <th (click)="setSortField('etat')" >
                                {{'languages.opportunite.label_status' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='etat','sort-down': sort_type == 'desc' && sort_field == 'etat', 'sort-active': sort_field=='etat'}">
                                    <i class="fa-solid fa-arrow-down-short-wide fa-lg ml-2"></i>

                                </div>
                            </th>
                            <th>{{'languages.affaire.Motifs' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody class="body-table">
                        <tr *ngFor="let affaires of affairesList.aaData; let i = index">
                            <td *ngIf="(isNouvelles || isTasks)   " >
                                <div class="mt-2">
                                    <label class="container">
                                        <input type="checkbox" *ngIf="!affaires.id_affected" class="check" [(ngModel)]="affaires.isSelected"
                                            name="list_name" value="{{affaires.id}}" (change)="isAllSelected()">
                                    </label>

                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center align-items-center">
                                    <!-- <div class="pt-1">
                                        <button type="button" class="btn-dt-list" data-toggle="modal" data-target="#ActionsPhone" (click)="getPhoneList(affaires.id_prospect)">
                                                <img src="/assets/icons/phone-icon.svg">
                                            </button>
                                    </div> -->
                                 

                                    <!-- In your template -->
<div class="mr-1">
    <a type="button" class="btn-dt-list" (click)="goToEdit(affaires)" [title]="'languages.navbar.consult' | translate">
        <i class="fa-solid eyebtnListing fa-eye fa fa-lg" ></i>
      
    </a>
  </div>
  
                                    <div>
                                        <input
                                          type="checkbox"
                                          id="permanent_{{ i }}"
                                          title="Add Favoris"
                                          class="star"
                                          [checked]="affaires.affaires_suivi"
                                        />
                                        <label
                                          for="permanent_{{ i }}"
                                          (click)="add_suivi_affaire(affaires)"
                                        ></label>
                                      </div>



                                </div>
                            </td>
                            <td>
                                {{
                                    affaires?.registration_number 
                                        ? affaires.registration_number 
                                        : '__'
                                }}
                            </td>
                            <td>
                                {{
                                    affaires?.contrat_type 
                                        ? affaires.contrat_type 
                                        : '__'
                                }}
                            </td>
                            
                            <td>
                              <ng-container *ngIf="affaires?.establishment_data_commercial_name || affaires?.establishment_data_official_name  || affaires?.legal_entity_official_name">
                                {{affaires.establishment_data_commercial_name || affaires.establishment_data_official_name ||affaires.legal_entity_official_name }}</ng-container>
                             </td>
                           <td  > 
                              <ng-container *ngIf="affaires?.establishment_director_data_last_name  || affaires?.establishment_director_data_first_name">
                                {{ affaires.establishment_director_data_last_name  + " " + affaires.establishment_director_data_first_name }}
                              </ng-container>
                            </td>
                            <td  *ngIf="affaires?.establishment_data_governorate">
                                {{affaires.establishment_data_governorate}}
                            </td>
                            
                            <td>
                              <ng-container *ngIf="affaires?.establishment_data_delegation">
                                {{affaires.establishment_data_delegation}}
                              </ng-container>
                             
                            </td>

                            

                              <td>
                                <ng-container *ngIf="affaires?.establishment_data_tax_number || affaires?.legal_entity_tax_number || affaires?.numero_matricule_fiscal_numero_IURNE ">
                                  {{affaires?.establishment_data_tax_number || affaires?.legal_entity_tax_number || affaires?.numero_matricule_fiscal_numero_IURNE}}
                                </ng-container>
                               
                              </td>

                              <td>
                                <ng-container *ngIf="affaires?.investor_phone_number ">
                                  {{affaires.investor_phone_number }}
                                </ng-container>
                               
                              </td>
                              
                              <td>
                                <ng-container *ngIf="affaires?.establishment_data_training_type ">
                                  {{affaires.establishment_data_training_type }}
                                </ng-container>
                               
                              </td>

                           
                         
                            
                            
                        <td>{{affaires.affaire_date_creation | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                            <td>
                                
                                {{affaires.name_service_affaire}}
                        </td>
                            <td>
                                <div class="btn-status d-flex justify-content-center"
                                    [style.background]="affaires.etat_background" [style.color]="affaires.etat_color">
                                    {{affaires.etat}}</div>
                            </td>
                            
                            
                            <td *ngIf="affaires.libele_motif == null">-</td>
                            <td *ngIf="affaires.libele_motif !==null">
                                <div class="pb-1" id="tagsList">
                                    <mat-chip-set class="example-chip ">
                                        <mat-chip class="example-box  mt-2"
                                            [ngStyle]="{'background-color':affaires.background_motif, 'color':affaires.color_motif}">
                                            {{affaires.libele_motif}}
                                        </mat-chip>
                                    </mat-chip-set>
                                </div>


                            </td>
                        </tr>

                    </tbody>

                </table>
                <div class="row d-flex justify-center align-items-center text-center ">
                    <p *ngIf="affairesList.aaData?.length===0"  class="col-12 noAffaire"> {{'languages.affaire.pas_daffaire' | translate}}  </p>
                   </div>

            </div>

            <div *ngIf="showListingEPFcree" class="table-responsive p-3">
                <table class="table align-items-center table-flush" id="dataTable1">
                    <thead class="head-table">
                        <tr>
                            <th  *ngIf="isNouvelles">
                                <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                                    (change)="checkUncheckAll()" />
                            </th>
                            <th >{{'languages.listing.actions' | translate}}
                            </th>


                            <th>{{'languages.SigafConfig.RagistrNum' | translate}}</th>
                            <th>{{'languages.SigafConfig.Denomination' | translate}}</th>
                            <th>{{'languages.affaire.directeur' | translate}}</th>
                            
                            <th>{{'languages.user.goverment' | translate}}</th>
                            
                            <th>{{'languages.SigafConfig.delegation' | translate}}</th>
                            <th>{{'languages.SigafConfig.matriculeFiscale' | translate}}</th>
                            <th>{{'languages.prospect.phone' | translate}}</th>
                            <th>{{'languages.opportunite.natureFormation' | translate}}</th>
                            <th>{{'languages.opportunite.label_creationdate' | translate}}</th>
        
                        </tr>
                    </thead>
                    <tbody class="body-table">
                        <tr *ngFor="let affaires of affairesList.aaData; let i = index">
                            <td *ngIf="isNouvelles" >
                                <div class="mt-2">
                                    <label class="container">
                                        <input type="checkbox" class="check" [(ngModel)]="affaires.isSelected"
                                            name="list_name" value="{{affaires.id}}" (change)="isAllSelected()">
                                    </label>

                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center align-items-center">
                                   
                                <div class="mr-1">
                                    <a type="button" class="btn-dt-list" (click)="goToEdit(affaires)" [title]="'languages.navbar.consult' | translate">
                                        <i class="fa-solid eyebtnListing fa-eye fa fa-lg" ></i>
                                    
                                    </a>
                                </div>
  
                                    <div>
                                        <input
                                          type="checkbox"
                                          id="permanent_{{ i }}"
                                          title="Add Favoris"
                                          class="star"
                                          [checked]="affaires.affaires_suivi"
                                        />
                                        <label
                                          for="permanent_{{ i }}"
                                          (click)="add_suivi_affaire(affaires)"
                                        ></label>
                                      </div>
                                </div>
                            </td>
                            <td>
                                {{
                                    affaires?.registration_number 
                                        ? affaires.registration_number 
                                        : '__'
                                }}
                            </td>
                            
                            <td>
                              <ng-container *ngIf="affaires?.establishment_data_commercial_name || affaires?.establishment_data_official_name  || affaires?.legal_entity_official_name">
                                {{affaires.establishment_data_commercial_name || affaires.establishment_data_official_name ||affaires.legal_entity_official_name }}</ng-container>
                             </td>
                           <td  > 
                              <ng-container *ngIf="affaires?.establishment_director_data_last_name  || affaires?.establishment_director_data_first_name">
                                {{ affaires.establishment_director_data_last_name  + " " + affaires.establishment_director_data_first_name }}
                              </ng-container>
                            </td>
                            <td  *ngIf="affaires?.establishment_data_governorate">
                                {{affaires.establishment_data_governorate}}
                            </td>
                            
                            <td>
                              <ng-container *ngIf="affaires?.establishment_data_delegation">
                                {{affaires.establishment_data_delegation}}
                              </ng-container>
                             
                            </td>

                            

                              <td>
                                <ng-container *ngIf="affaires?.establishment_data_tax_number || affaires?.legal_entity_tax_number || affaires?.numero_matricule_fiscal_numero_IURNE ">
                                  {{affaires?.establishment_data_tax_number || affaires?.legal_entity_tax_number || affaires?.numero_matricule_fiscal_numero_IURNE}}
                                </ng-container>
                               
                              </td>

                              <td>
                                <ng-container *ngIf="affaires?.investor_phone_number ">
                                  {{affaires.investor_phone_number }}
                                </ng-container>
                               
                              </td>
                              
                              <td>
                                <ng-container *ngIf="affaires?.establishment_data_training_type ">
                                  {{affaires.establishment_data_training_type }}
                                </ng-container>
                               
                              </td>

                           
                         
                            
                            
                        <td>{{affaires.affaire_date_creation | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                        </tr>

                    </tbody>

                </table>
                <div class="row d-flex justify-center align-items-center text-center ">
                    <p *ngIf="affairesList.aaData?.length===0"  class="col-12 noAffaire"> {{'languages.affaire.pas_daffaire' | translate}}  </p>
                   </div>

            </div>
        </div>
        <div class="navbar-center row mb-4">
            <div class="col pt-1">
                <div class="row w-50 flex-nowrap">
                    <div class="col mt-1">
                        <p>{{'languages.miniListing.Afficher' | translate}}</p>
                    </div>
                    <div class="col mx-1">
                        <select [(ngModel)]="limit" (change)="updateListAffaires()"
                            class="bg-white border pt-1 rounded font-weight-bold">
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div class="col mt-1">
                        <p class="text-nowrap"> {{'languages.miniListing.pagesPerPage' | translate}} {{totalLength}}  {{'languages.miniListing.element' | translate}} </p>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <nav aria-label="Page navigation example" *ngIf="totalPages>0">
                    <ul class="pagination pg-blue">
                        <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Previous">
                                <i class="fa fa-chevron-left "></i>
                            </a>
                        </li>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+page-3>0" class="page-item">
                                <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page -
                                    3}}</a>
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+page<totalPages" class="page-item">
                                <a (click)="changepage(i+page)" class="page-link mt-2 border rounded"
                                    [ngClass]="{'active-page':page==(i+page)}">
                                    {{i + page}}
                                </a>
                            </li>
                        </ng-container>
                        <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
                        <li class="page-item">
                            <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                                [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
                        </li>
                        <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Next">
                                <i class="fa fa-chevron-right"></i>
                            </a>
                        </li>
                        <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col">
                <div class="row position-absolute flex-nowrap" style="right: 0px">
                    <div class="col text-nowrap text-right mt-2">
                        <p>{{'languages.listing.label_goto' | translate}}</p>
                    </div>
                    <div class="col mt-1 ml-2">
                        <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48"
                            (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number"
                            style="margin-left: 4px;" size="1"
                            class="page_input bg-white border rounded font-weight-bold" [value]="page"
                            max="{{totalPages}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--------------------------------Action Phone----------------------------------->
<div class="modal fade" id="ActionsPhone" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog model-lg" role="document">
        <div class="modal-content" style="width: 60rem; margin-left: -135px;">
            <div class="modal-header">
<i class="fa-solid fa-phone-flip fa-2x"></i>
                <h2 style="margin-left: 15px">{{'languages.opportunite.label_phoneactions' | translate}}</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <ng-container *ngIf="show_loading_add">
                            <div class="col-12 loader-spinner">
                                <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row" *ngIf="list_tel.mobile!= null && list_tel.mobile!=''">
                            <div class="col-3 text-center">
                                {{'languages.prospect.Mobile' | translate}}
                            </div>
                            <div class="col-6 text-center">
                                {{this.list_tel.mobile}}
                            </div>
                            <div class="col-3 text-center">

                                <button class="btn" (click)="callVoip(list_tel.mobile)">
                                    <i class="fa-solid fa-phone-flip fa-lg"
                                        style="margin-left: 5px; color: #43ac43;"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="list_tel.tel!= null && list_tel.tel!=''">
                            <div class="col-3 text-center">
                                {{'languages.prospect.phone' | translate}}
                            </div>
                            <div class="col-6 text-center">
                                {{this.list_tel.tel}}
                            </div>
                            <div class="col-3 text-center">

                                <button class="btn" (click)="callVoip(list_tel.tel)">
                                    <i class="fa-solid fa-phone-flip fa-lg"
                                    style="margin-left: 5px; color: #43ac43;"></i>

                                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="list_tel.tel2!= null && list_tel.tel2!=''">
                            <div class="col-3 text-center">
                                {{'languages.prospect.phone' | translate}} 2
                            </div>
                            <div class="col-6 text-center">
                                {{this.list_tel.tel2}}
                            </div>
                            <div class="col-3 text-center">
                                <button class="btn" (click)="callVoip(list_tel.tel2)">
                                    <i class="fa-solid fa-phone-flip fa-lg"
                                    style="margin-left: 5px; color: #43ac43;"></i>

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <button class="btn-style" data-dismiss="modal">{{'languages.buttons.fermer' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
