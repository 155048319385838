import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {Process} from '../../entity/Process';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.css']
})
export class StatusBarComponent implements OnInit,OnChanges {
  @Input() listOfProcesses: Process [] = [];
  // private _hideStatus: any = "";
  @Output() onClickStatus: EventEmitter<number> = new EventEmitter<number>();
  widthPercent:number=0;

  @Input()
  // set hideStatus(value: any) {
  //   this._hideStatus = value;
  //   this.filterService();
  //   }
    
  ngOnInit(): void {
  }

  StatusClicked(i: number) {
    this.onClickStatus.emit(i);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.widthPercent=100/this.listOfProcesses?.length
    // if (changes.listOfProcesses) {
    //  this.filterService();
    // }
  }
// filterService(){
//   if (this._hideStatus){
//     this.listOfProcesses = this.listOfProcesses.filter(process => process.service != environment.serviceCompelment);

//   }
// }
  
}
