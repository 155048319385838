import {
  ChangeDetectorRef,
  Component,
  Output,
  OnInit,
  ViewChild,
  EventEmitter,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { AffaireDetailsComponent } from '../affaire-details.component';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { HistoriquesAffaires } from '../../../../entity/Affaires/HistoriquesAffaires';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UniformiserDateToLocal } from 'src/app/shared/Utils/sharedFunctions';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { Subscription } from 'rxjs';
import { HistoryService } from '../history.service';
@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.css'],
})
export class HistoriqueComponent implements OnInit, OnDestroy {
  dataTable: any;

  json_object: any;
  historiques: HistoriquesAffaires[] = [];
  public editorOptions: JsonEditorOptions;
  unsubscribeGetHistoriqueAffaires: Subscription;
  displayedColumns: string[] = ['date', 'user', 'commentaire'];
  dataSource: MatTableDataSource<HistoriquesAffaires>;
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() allowed_to_update_affaire: boolean;
  fetchDataSignal: boolean = false;
  private fetchDataSubscription: Subscription;
  constructor(
    private affaireDetailsComponent: AffaireDetailsComponent,
    private apiAffairesService: ApiAffairesService,
    private HistoryService: HistoryService,

    private chRef: ChangeDetectorRef
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['view', 'code', 'text']; // set all allowed modes
    this.editorOptions.mode = 'view'; //
    this.apiAffairesService.listendochistorique().subscribe((m: any) => {
      this.getHistorique();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeGetHistoriqueAffaires?.unsubscribe();
  }

  ngOnInit(): void {
    this.updatePaginatorLabels();
    this.fetchDataSubscription = this.HistoryService.fetchData$.subscribe((signal) => {
      this.fetchDataSignal = signal;

      if (this.fetchDataSignal) {
        this.getHistorique();
      }
    });

    this.getHistorique();
  }

  ngAfterViewInit(): void {
    
    this.paginator._intl.itemsPerPageLabel = 'Lignes par page';
    this.paginator._intl.changes.next(); // Notify Angular Material about the change
  }
  updatePaginatorLabels(): void {
    if (this.paginator) {
      this.paginator._intl.itemsPerPageLabel = 'Lignes par page';
    }
  }

  getJson(json_object) {
    this.json_object = json_object;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getHistorique() {
    this.unsubscribeGetHistoriqueAffaires = this.apiAffairesService
      .GetHistoriqueAffaires(this.affaireDetailsComponent.affaire_id)
      .subscribe((data: HistoriquesAffaires[]) => {
        this.historiques = data.reverse();
        this.historiques.forEach((element, index) => {
          this.historiques[index].date_action = UniformiserDateToLocal(element.date_action);
        });
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.chRef.detectChanges();
      });
  }

  changeDate(date: string) {
    return date.substr(0, 10) + ' ' + date.substr(11, 8);
  }
}
