import swal from "sweetalert2";
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DocumentationService } from '../../services/documentationService/documentation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent implements OnInit {
  apiGroups: any[] = []; // Store the list of API groups
  selectedGroup: any = null; // Currently selected group
  selectedApi: any = null; // Currently selected API
  formErrors: { [key: string]: string } = {}; // Track validation errors for form fields
  showTable ;boolean = false
  saveLoader: boolean =false;
  data: any;
  constructor(
    private apiServiceDocumentation: DocumentationService,
    private http: HttpClient,
    private translate: TranslateService,
    private sharedMenuObserverService: SharedMenuObserverService,
  ) {}
  alerts: any;
  buttons: any;
  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        
        this.sharedMenuObserverService.updateMenu(object.navbar.consultationExterne);
      });});
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.sharedMenuObserverService.updateMenu(object.navbar.consultationExterne);
       
      });
    this.http.get<any[]>('assets/apis/apisDocumentation.json').subscribe(data => {
      this.apiGroups = data; 
    });
  }

  // Handle group selection
  onGroupSelect(group: any): void {
    this.selectedGroup = group; 
    this.selectedApi = null; 
    this.formErrors = {}; 
    this.showTable
    this.showTable =false
  }

 
  onApiSelect(api: any): void {
    this.selectedApi = api; 
    this.formErrors = {}; 
    this.tableData = [];
    this.showTable =false
    if (this.selectedApi && this.selectedApi.fields) {
      this.selectedApi.fields.forEach((field: any) => {
        field.value = ''; 
      });
    }
  }

  onFieldChange(field: any, fieldValue: string): void {
    // Call the validateField method to check the validity of the field and show errors
    this.validateField(field, fieldValue);
  }

  validateField(field: any, fieldValue: string): boolean {
    let isValid = true;
    let errorMessage = '';

   
    if (field.required && !fieldValue) {
      isValid = false;
      errorMessage = `${field.label} ${this.alerts.isRequired}.`;
    }

  
    if (field.min && fieldValue.length < field.min) {
      isValid = false;
      errorMessage = `${field.label} ${this.alerts.mustBeAtLeast} ${field.min} ${this.alerts.characterslong}.`;
    }

  
    if (field.max && fieldValue.length > field.max) {
      isValid = false;
      errorMessage = `${field.label} ${this.alerts.mustNotExceed} ${field.max} ${this.alerts.characters}.`;
    }

    
    if (field.pattern && !new RegExp(field.pattern).test(fieldValue)) {
      isValid = false;
      errorMessage = `${field.label} ${this.alerts.doesNotMatch} (${field.examplePattern})`;
    }

  
    if (!isValid) {
      this.formErrors[field.name] = errorMessage;
    } else {
      delete this.formErrors[field.name]; 
    }

    return isValid;
  }

  tableData: { key: string; value: any }[] = [];
  displayedColumns: string[] = ['champ', 'valeur'];

  // Handle form submission
  onSubmit(): void {
    if (this.selectedApi) {
      let isFormValid = true;

      const queryParams: { [key: string]: any } = {};
  
     
      this.selectedApi.fields.forEach(field => {
        const fieldValue = field.value || ''; 
        const isFieldValid = this.validateField(field, fieldValue);
  
       
        if (!isFieldValid) {
          isFormValid = false;
        }
  
        
        queryParams[field.name] = fieldValue;
      });
  
      if (isFormValid) {
        this.saveLoader = true;
        const endpoint = this.selectedApi.endpoint; 
  
     
        this.apiServiceDocumentation
          .callApi(this.selectedApi.title, {}, endpoint, queryParams)
          .subscribe(response => {
            this.showTable=true
            this.saveLoader = false;
            if (response && response.data) {
              this.data=response.data
              this.tableData = Object.entries(response.data).map(([key, value]) => ({
                key,
                value: value || '-', 
              }));
             
            }
          },(error: any) => {
            this.showTable=false
            this.saveLoader = false;
            swal.fire({
                    title: this.alerts.badHappened,
                    text:error.error.message,
                    confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#d53a3a',
                    icon: 'warning',
                  });
          });
      } else {
        this.saveLoader = false;
        this.showTable=false
        swal.fire({
                title: this.alerts.verifyfields,
                confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#d53a3a',
                icon: 'error',
              });
       
      }
    }
  }
  
}
