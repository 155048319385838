/**
 * Documentation Geoprod
 * Description des web services de Remoteprod
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ModelObject } from '../model/modelObject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {environment} from "../../../environments/environment";


@Injectable()
export class ApiNotificationService {

  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Sert a insérer ou  modifier une  notfication
   *
   * @param cle cle de l&#x27;operation update ou create &lt;br&gt; Vous pouvez modifier une notification avec des date vide
   * @param titre titre de notification
   * @param message message de notification
   * @param userNot Identifiant de l&#x27;utilisateur qu&#x27;on lui affecte la notification
   * @param url Url de notification
   * @param id Identifiant de notification a modifier (Champs obligatoire pour la modification)
   * @param date date de notification sous format YYYY-MM -DD si le champs est vide la date courante est prise
   * @param heure heure de notification sous format HH:MM:S S si champ est vide , l&#x27;heure courante est prise
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createOrUpdateNotificationGet(cle: string, titre: ModelObject, message: ModelObject, userNot: ModelObject, url: ModelObject, id?: ModelObject, date?: ModelObject, heure?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createOrUpdateNotificationGet(cle: string, titre: ModelObject, message: ModelObject, userNot: ModelObject, url: ModelObject, id?: ModelObject, date?: ModelObject, heure?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createOrUpdateNotificationGet(cle: string, titre: ModelObject, message: ModelObject, userNot: ModelObject, url: ModelObject, id?: ModelObject, date?: ModelObject, heure?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createOrUpdateNotificationGet(cle: string, titre: ModelObject, message: ModelObject, userNot: ModelObject, url: ModelObject, id?: ModelObject, date?: ModelObject, heure?: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (cle === null || cle === undefined) {
      throw new Error('Required parameter cle was null or undefined when calling createOrUpdateNotificationGet.');
    }

    if (titre === null || titre === undefined) {
      throw new Error('Required parameter titre was null or undefined when calling createOrUpdateNotificationGet.');
    }

    if (message === null || message === undefined) {
      throw new Error('Required parameter message was null or undefined when calling createOrUpdateNotificationGet.');
    }

    if (userNot === null || userNot === undefined) {
      throw new Error('Required parameter userNot was null or undefined when calling createOrUpdateNotificationGet.');
    }

    if (url === null || url === undefined) {
      throw new Error('Required parameter url was null or undefined when calling createOrUpdateNotificationGet.');
    }




    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (cle !== undefined && cle !== null) {
      queryParameters = queryParameters.set('cle', <any>cle);
    }
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }
    if (titre !== undefined && titre !== null) {
      queryParameters = queryParameters.set('titre', <any>titre);
    }
    if (message !== undefined && message !== null) {
      queryParameters = queryParameters.set('message', <any>message);
    }
    if (userNot !== undefined && userNot !== null) {
      queryParameters = queryParameters.set('user_not', <any>userNot);
    }
    if (date !== undefined && date !== null) {
      queryParameters = queryParameters.set('date', <any>date);
    }
    if (heure !== undefined && heure !== null) {
      queryParameters = queryParameters.set('heure', <any>heure);
    }
    if (url !== undefined && url !== null) {
      queryParameters = queryParameters.set('url', <any>url);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/create_or_update_notification`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher la liste des comminuqués de l&#x27;utilisateur connecté entre deux date
   *
   * @param date Date lancment de communiqué
   * @param time heure de lancement communiqué
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCommuniquesGet(date: ModelObject, time: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public getCommuniquesGet(date: ModelObject, time: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public getCommuniquesGet(date: ModelObject, time: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public getCommuniquesGet(date: ModelObject, time: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (date === null || date === undefined) {
      throw new Error('Required parameter date was null or undefined when calling getCommuniquesGet.');
    }

    if (time === null || time === undefined) {
      throw new Error('Required parameter time was null or undefined when calling getCommuniquesGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (date !== undefined && date !== null) {
      queryParameters = queryParameters.set('date', <any>date);
    }
    if (time !== undefined && time !== null) {
      queryParameters = queryParameters.set('time', <any>time);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/get_communiques`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sert a afficher les  notification d’un utilisateur ayant une date inerieur a date et heure inferieur a heure
   *
   * @param date Date de notification
   * @param time Heure de notifiaction
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getNotificationGet( observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    // if (date === null || date === undefined) {
    //   throw new Error('Required parameter date was null or undefined when calling getNotificationGet.');
    // }

    // if (time === null || time === undefined) {
    //   throw new Error('Required parameter time was null or undefined when calling getNotificationGet.');
    // }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/get_notifications`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public markAllAsRead( observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    // if (date === null || date === undefined) {
    //   throw new Error('Required parameter date was null or undefined when calling getNotificationGet.');
    // }

    // if (time === null || time === undefined) {
    //   throw new Error('Required parameter time was null or undefined when calling getNotificationGet.');
    // }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('put',`${this.basePath}/view_all_notifications`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


   /**
   * Sert a afficher les  notification d’un utilisateur ayant une date inerieur a date et heure inferieur a heure
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetDateNotifications(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/get_date?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet d&#x27;modifier la date et l&#x27;heure d&#x27;une notification suivant son identifiant codé en md5
   *
   * @param date Nouveau date notification sous format YYYY-MM-DD
   * @param heure Nouveau date notification sous format HH:MM:SS
   * @param id Identifiant de notificaction codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDateNotificationGet(date: ModelObject, heure: ModelObject, id?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateDateNotificationGet(date: ModelObject, heure: ModelObject, id?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateDateNotificationGet(date: ModelObject, heure: ModelObject, id?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateDateNotificationGet(date: ModelObject, heure: ModelObject, id?: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (date === null || date === undefined) {
      throw new Error('Required parameter date was null or undefined when calling updateDateNotificationGet.');
    }

    if (heure === null || heure === undefined) {
      throw new Error('Required parameter heure was null or undefined when calling updateDateNotificationGet.');
    }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }
    if (date !== undefined && date !== null) {
      queryParameters = queryParameters.set('date', <any>date);
    }
    if (heure !== undefined && heure !== null) {
      queryParameters = queryParameters.set('heure', <any>heure);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/update_date_notification`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet de mettre le status de la notification a 1 c&#x27;est a dire resolue
   *
   * @param id Identifiant de notification codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateStatusNotificationGet(id: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateStatusNotificationGet(id: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateStatusNotificationGet(id: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public updateStatusNotificationGet(id: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateStatusNotificationGet.');
    }

    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    // if (id !== undefined && id !== null) {
    //   queryParameters = queryParameters.set('id', <any>id);
    // }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('PUT',`${this.basePath}/update_notification/${id}`,
      {
       
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
